import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { completedStatistics, resetOrders, resetStatistics, setOrders, setStatistics } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'
import DateRanger from 'components/common/DateRanger'

// import custom components
import CompletedTable from './CompletedTable'
import OrderDetails from 'pages/orders/OrderDetails'

// import constants
import { APP_NAME, ORDERS_SHOW, STATISTICS_COMPLETEDS } from 'helpers/constants'

// import utilities
import { convertDatesOnTimestamps } from 'helpers/utilities'


class CompletedOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            start: moment(),
            end: moment(),
            detailsVisible: false,
        }
    }

    componentDidMount() {
        try {// Load completed orders
            this.onLoaded()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {

            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetStatistics(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On Loaded.
     * 
     */
    onLoaded = () => {
        try {
            // Get props values
            let { onListStatistics } = this.props

            // Get state values
            let { start, end } = this.state

            // Get converted dates
            let timestamps = convertDatesOnTimestamps(start, end)

            // Load statistics
            onListStatistics(timestamps.start, timestamps.end, STATISTICS_COMPLETEDS)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onDateRanged = (start, end) => {
        // Save values
        this.setState({ start: start, end: end })

        // Get props values
        let { onListStatistics, onResetStatistics } = this.props

        // Get converted dates
        let timestamps = convertDatesOnTimestamps(start, end)

        // Reset statistics current values
        onResetStatistics(false)

        // Load statistics with new values
        onListStatistics(timestamps.start, timestamps.end, STATISTICS_COMPLETEDS)
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected._id }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { t, statistics, option, loading, success, error } = this.props
        const { start, end, detailsVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Les courses abouties")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Les courses abouties")}
                            subtitle={(statistics.length > 0) ? (`(${statistics.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === STATISTICS_COMPLETEDS)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === STATISTICS_COMPLETEDS)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <Row>
                                        <Col xl="9" sm="12" />
                                        <Col xl="3" sm="12">
                                            <DateRanger
                                                start={start} end={end}
                                                onRanged={this.onDateRanged}
                                            />
                                        </Col>

                                        <Col sm="12" className="my-2" />

                                        <Col xl="12">
                                            <CompletedTable
                                                items={statistics}
                                                start={start.format("DD/MM/YYYY")}
                                                end={end.format("DD/MM/YYYY")}
                                                onShowed={this.onShowed}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
CompletedOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    statistics: PropTypes.array,
    onResetStatistics: PropTypes.func,
    onListStatistics: PropTypes.func,
    onSetStatistics: PropTypes.func
}
const mapStateToProps = state => ({
    statistics: state.statistiques.completed_statistics,
    error: state.statistiques.error,
    option: state.statistiques.option,
    success: state.statistiques.success,
    loading: state.statistiques.loading
})
const mapDispatchToProps = dispatch => ({
    onSetStatistics: (data) => dispatch(setStatistics(data)),
    onResetStatistics: (all) => dispatch(resetStatistics(all)),
    onListStatistics: (start, end, option) => dispatch(completedStatistics(start, end, option)),
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(CompletedOrders)))