import React from "react"
import PropTypes from "prop-types"

// import router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

// import redux
import { connect } from "react-redux"

// Import routes
import { protectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// Import layouts
import Container from "./components/layout/Container"
import Auther from "./components/layout/Auther"

// Import scss
import "./assets/scss/theme.scss"

// Import toast
import { ToastContainer, Slide } from 'react-toastify'
import '../node_modules/react-toastify/dist/ReactToastify.css'

// import drawer styles
import '../node_modules/react-drawer/lib/react-drawer.css';

// import custom components
import NoMatch from "components/common/NoMatch"


class App extends React.Component {

	constructor(props) {
		super(props)

		// init state
		this.state = {
			hasError: false
		}
	}

	componentDidCatch(error, info) {
		// Affiche une UI de repli    
		this.setState({ hasError: true });

		// Handle error
		console.error({ error, info }, "COMPONENT ERROR")
	}

	render() {
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{publicRoutes.map((route, index) => (
							<AppRoute
								exact
								key={index}
								path={route.path}
								layout={Auther}
								component={route.component}
								isProtected={false}
								roles={[]}
							/>
						))}

						{protectedRoutes.map((route, index) => (
							<AppRoute
								exact
								key={index}
								path={route.path}
								layout={Container}
								component={route.component}
								isProtected={true}
								roles={route.roles}
							/>
						))}

						<Route component={NoMatch} />
					</Switch>
				</Router>
				<ToastContainer
					hideProgressBar newestOnTop
					closeButton={false} icon={false}
					position="top-right" limit={3}
					transition={Slide} autoClose={2000}
					pauseOnFocusLoss={false}
				/>
			</React.Fragment>
		)
	}
}
App.propTypes = {
	layout: PropTypes.object,
}
const mapStateToProps = state => {
	return {
		layout: state.ground,
	}
}
export default connect(mapStateToProps, null)(App)
