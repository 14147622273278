import React from "react"
import PropTypes from 'prop-types'

// import router
import { withRouter } from "react-router-dom"

// import redux, actions
import { connect } from "react-redux"
import { changeSidebarTheme, setOrders } from "../../store/actions"

// import toaster
import { toast } from 'react-toastify'

// import customs components
import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"

// import socket
import { socket } from "helpers/socket"

// import constants
import { USER_AGENCY, USER_KEY, USER_SUPERVISOR } from "helpers/constants"

// import notification sound
import sample from "../../assets/medias/sample.mp3"


class Container extends React.Component {

	constructor(props) {
		super(props)

		this.notificationSound = new Audio(sample)

		this.state = {
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
		}
	}

	componentDidMount() {
		try {
			// Get logged user data
			let { role, _id } = JSON.parse(localStorage.getItem(USER_KEY))

			// Connect to socket et add event listener
			if ([USER_SUPERVISOR.key, USER_AGENCY.key].includes(role) && socket.disconnected) socket.connect()
			socket.on('connect', (message) => { socket.emit('join', (`${_id}`)) })
			socket.on('joined', (message) => { console.log(message) })
			socket.on('ping_pong', (message) => {
				console.log({ message }, "FROM PING PONG")
				this.onOrderReceived({ code: "#A-0PINGPONG" })
			})
			socket.on('new_order', (message) => {
				if (role === USER_SUPERVISOR.key) this.onOrderReceived(message)
			})
			socket.on('forward_done', (message) => { this.onOrderForwarded(message) })

			//Get props
			const { isPreloader, sidebarTheme, onChangeSidebarTheme } = this.props

			//Handle Preloader
			if (isPreloader === true) {
				document.getElementById("preloader").style.display = "block"
				document.getElementById("status").style.display = "block"

				setTimeout(function () {
					document.getElementById("preloader").style.display = "none"
					document.getElementById("status").style.display = "none"
				}, 2500)
			} else {
				document.getElementById("preloader").style.display = "none"
				document.getElementById("status").style.display = "none"
			}

			// Scroll Top to 0
			window.scrollTo(0, 0)

			// Change sidebar theme
			if (sidebarTheme) onChangeSidebarTheme(sidebarTheme)
		} catch (error) {
			console.error(error)
		}
	}

	componentWillUnmount() {
		try {
			// Remove socket
			socket.off()
		} catch (error) {
			console.error(error)
		}
	}

	/**
	* On new order received.
	* 
	* @param {*} newOrder 
	*/
	onOrderReceived = (newOrder) => {
		try {
			// Get current pathname
			const { pathname } = this.props.location

			// Get extra message value
			let extra = (pathname.includes("/daily-orders") ? ". Actualiser la liste pour voir la course." : ".")

			// Play sound
			this.notificationSound.play()

			// Show toast
			toast.success(`Nouvelle course #${newOrder.code} créée${extra}`)
		} catch (error) {
			console.error(error)
		}
	}

	/**
	* On order successful forwarded.
	* 
	* @param {*} message 
	*/
	onOrderForwarded = (message) => {
		try {
			const { order, onSetOrders } = this.props
			if (order.transfert && (order.code === message.order_code)) {
				onSetOrders({
					order: {
						...order, transfert: {
							...order.transfert, forwarded: true
						}
					}
				})
			}
		} catch (error) {
			console.error(error)
		}
	}


	render() {

		const { isMobile } = this.state
		const { children, sidebarTheme } = this.props

		return (
			<React.Fragment>
				<div id="preloader">
					<div id="status">
						<div className="spinner-chase">
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
						</div>
					</div>
				</div>

				<div id="layout-wrapper">
					<Header />

					<Sidebar theme={sidebarTheme} isMobile={isMobile} />

					<div className="main-content">
						{children}
					</div>

					<Footer />
				</div>
			</React.Fragment>
		)
	}

}
Container.propTypes = {
	onChangeSidebarTheme: PropTypes.func,
	children: PropTypes.any,
	isPreloader: PropTypes.bool,
	sidebarTheme: PropTypes.any,
	location: PropTypes.object
}
const mapStateToProps = state => ({
	...state.ground,
	order: state.commandes.order
})
const mapDispatchToProps = dispatch => ({
	onChangeSidebarTheme: theme => dispatch(changeSidebarTheme(theme)),
	onSetOrders: (data) => dispatch(setOrders(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container))
