import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import {
    addCustomer, editCustomer, listCustomers, resetCustomers, setCustomers
} from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom components
import PartnerTable from './PartnerTable'
import PartnerForm from './PartnerForm'
import PartnerCard from './PartnerCard'

// import constants
import {
    APP_NAME, PARTNERS_ADD, PARTNERS_EDIT, PARTNERS_LIST, PARTNERS_SHOW
} from 'helpers/constants'

// import utilities
import {
    formatPhoneNumberForDisplay, formatPhoneNumberForRequest, generateRandomCode
} from 'helpers/utilities'


class Partners extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formVisible: false,
            cardVisible: false
        }
    }

    componentDidMount() {
        try {// Load partners
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetPartners } = this.props

                if (success) {
                    // Close modals
                    if ([PARTNERS_ADD, PARTNERS_EDIT].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === PARTNERS_ADD) {
                        toast.success("Nouveau partenaire ajouté avec succès.")
                    } else if (option === PARTNERS_EDIT) {
                        toast.success("Un partenaire mis à jour avec succès.")
                    }

                    //Reset global state
                    onResetPartners(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetPartners(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { partner, onSetPartners } = this.props

            //Store values
            onSetPartners({
                customer: { ...partner, [name]: checked }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On generated.
     * 
     */
    onGenerated = () => {
        try {
            //Get props
            let { partner, onSetPartners } = this.props

            //Store values
            onSetPartners({
                customer: { ...partner, password: generateRandomCode(8, true) }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            //Get target
            let { value, name } = event.target

            //Get props
            let { partner, onSetPartners } = this.props

            //Store values
            onSetPartners({
                customer: { ...partner, [`${name}Count`]: `${value.length}` }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddPartner, onEditPartner, option, partner } = this.props

            switch (option) {
                case PARTNERS_ADD:
                    onAddPartner({
                        name: values.name.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        other_phone: formatPhoneNumberForRequest(values.other_phone.trim()),
                        email: values.email.trim(),
                        ifu: values.ifu.trim(),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        address: values.address.trim(),
                        enabled: partner.enabled
                    }, option)
                    break;
                case PARTNERS_EDIT:
                    onEditPartner({
                        name: values.name.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        other_phone: formatPhoneNumberForRequest(values.other_phone.trim()),
                        email: values.email.trim(),
                        ifu: values.ifu.trim(),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        address: values.address.trim(),
                        enabled: partner.enabled
                    }, partner._id, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetPartners, onResetPartners } = this.props

            //Reset state
            onResetPartners(false)

            //Init state
            onSetPartners({
                customer: { enabled: true, addressCount: "" },
                option: PARTNERS_ADD
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetPartners, onResetPartners } = this.props

            //Reset state
            onResetPartners(false)

            //Init state
            onSetPartners({
                customer: {
                    ...item,
                    addressCount: `${item.address.length}`,
                    phone: formatPhoneNumberForDisplay(item.phone),
                    other_phone: formatPhoneNumberForDisplay(item.other_phone)
                },
                option: PARTNERS_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Show.
     * 
     * @param {*} item 
     */
    onShowing = (item) => {
        try {
            //Get props
            let { onSetPartners, onResetPartners } = this.props

            //Reset state
            onResetPartners(false)

            //Init state
            onSetPartners({ customer: { ...item }, option: PARTNERS_SHOW })

            //Show modal
            this.onToggled("cardVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On reloaded.
     * 
     */
    onLoaded = () => {
        try {// Load partners
            let { partners, onResetPartners, onListPartners } = this.props
            if (partners.length > 0) onResetPartners(true)
            onListPartners(PARTNERS_LIST)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, partners, partner, error, option, loading } = this.props
        const { formVisible, cardVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Clients Contractuels")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Clients Contractuels")}
                            subtitle={(partners.length > 0) ? (`(${partners.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === PARTNERS_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === PARTNERS_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <PartnerTable
                                        items={partners}
                                        onAdding={this.onAdding}
                                        onEditing={this.onEditing}
                                        onShowing={this.onShowing}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(formVisible) ? (
                    <PartnerForm
                        opened={formVisible}
                        option={[PARTNERS_ADD, PARTNERS_EDIT].includes(option) ? option : ""}
                        error={[PARTNERS_ADD, PARTNERS_EDIT].includes(option) ? error : ""}
                        loading={[PARTNERS_ADD, PARTNERS_EDIT].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        onSubmited={this.onSubmited}
                        onChanged={this.onChanged}
                        onChecked={this.onChecked}
                        onGenerated={this.onGenerated}
                        partner={partner}
                    />
                ) : null}
                {(cardVisible) ? (
                    <PartnerCard
                        opened={cardVisible}
                        option={[PARTNERS_SHOW].includes(option) ? option : ""}
                        error={[PARTNERS_SHOW].includes(option) ? error : ""}
                        loading={[PARTNERS_SHOW].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        partner={partner}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Partners.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    partners: PropTypes.array,
    partner: PropTypes.object,
    onListPartners: PropTypes.func,
    onSetPartners: PropTypes.func,
    onResetPartners: PropTypes.func,
    onAddPartner: PropTypes.func,
    onEditPartner: PropTypes.func
}
const mapStateToProps = state => ({
    partners: state.clients.customers,
    partner: state.clients.customer,
    error: state.clients.error,
    option: state.clients.option,
    success: state.clients.success,
    loading: state.clients.loading
})
const mapDispatchToProps = dispatch => ({
    onSetPartners: (data) => dispatch(setCustomers(data)),
    onResetPartners: (all) => dispatch(resetCustomers(all)),
    onAddPartner: (data, option) => dispatch(addCustomer(data, option)),
    onEditPartner: (data, id, option) => dispatch(editCustomer(data, id, option)),
    onListPartners: (option) => dispatch(listCustomers(true, false, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Partners)))