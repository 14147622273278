import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Col, Row } from 'reactstrap'

// import router link
import { Link } from 'react-router-dom'

// import constants
import { ORDERS_ADD } from 'helpers/constants'

// import custom common components
import Loader from 'components/common/Loader'

// import utilities
import { formatPhoneNumberForDisplay } from 'helpers/utilities'


const SaveTab = React.memo(({ order, loading, option, error, success, onRestarted }) => {

    const { customer } = order
    const customer_type = (customer && customer.partner) ? "contractuel" : "occasionnel"
    const customer_name = (customer && customer.name) ? customer.name : "" 
    const customer_phone = (customer && customer.phone) ? formatPhoneNumberForDisplay(customer.phone) : "" 

    return (
        <React.Fragment>
            <div style={{ minHeight: '350px' }}>
                {(loading && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Loader color="primary" />
                            <center>
                                <p className="m-0 p-2">
                                    Enregistrement de la course. Veuillez patienter...
                                </p>
                            </center>
                        </Col>
                    </Row>
                ) : ((error !== "") && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Alert color="danger" className="m-0 d-flex align-items-center justify-content-between">
                                {error}{" "}
                                <Link to="#" className="alert-link" onClick={() => onSaved()}>
                                    Réessayer
                                </Link>
                            </Alert>
                        </Col>
                    </Row>
                ) : (success && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title rounded-circle bg-success bg-soft">
                                        <i className="bx bx-check-double h1 mb-0 text-success" />
                                    </div>
                                </div>

                                <div className="p-2 mt-4">
                                    <h3>
                                        Succès !
                                        <small className="d-block text-muted fs-14 mt-1">
                                            Une course a bien été ajoutée pour le client {customer_type} {" "}
                                            <code className="d-block mt-2">
                                                <strong>{customer_name}</strong> {" "} - {" "}
                                                <strong>{formatPhoneNumberForDisplay(customer_phone)}</strong>
                                            </code> {" "}
                                        </small>
                                        <small className="d-block text-muted fs-12 mt-2">
                                            Le client recevra un sms de notification.
                                        </small>
                                    </h3>
                                </div>

                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <Link to="/agency-orders" className="btn btn-primary me-1">
                                        Liste des courses
                                    </Link>
                                    <Link
                                        to="#" className="btn btn-outline-primary ms-1"
                                        onClick={() => onRestarted()}
                                    >
                                        Nouvelle course
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                ) : null}
            </div>
        </React.Fragment>
    );
    
});
SaveTab.propTypes = {
    order: PropTypes.object,
}
SaveTab.defaultProps = {
    order: {},
}
export default SaveTab