import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import Link
import { Link } from "react-router-dom"

// import components
import {
    Alert, Card, CardBody, CardHeader, Col, Input, Row
} from "reactstrap"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import utilities
import { getConstantObject, upperFirst } from 'helpers/utilities'

// import constants
import {
    PARTNERS_SHOW, INVOICES_PAID, INVOICES_PAID_DETAILS, USER_BILLER,
    INVOICES_PAID_NOTIFY, INVOICES_PER_PAGE, INVOICES_UNPAID, USER_KEY
} from 'helpers/constants'

// import custom common components
import Paginator from 'components/common/Paginator'
import Filter from 'components/common/Filter'

// import custom components
import InvoiceSearch from './InvoiceSearch'

// COLORS
const RANDOMS_COLORS = ["primary", "info", "blue", "indigo"]


class InvoiceList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentInvoices: [],
            currentPage: 0,
            totalPages: 0,
            query: "",
            userRole: ""
        }
    }

    componentDidMount() {
        try {// extract user role
            if (localStorage.getItem(USER_KEY)) {
                let { role } = JSON.parse(localStorage.getItem(USER_KEY))
                this.setState({ userRole: role })
            }
        } catch (error) {
            console.log(error)
        }
    }

    onPageChanged = (data) => {
        const { items, totalPerPage } = this.props
        const { currentPage, totalPages } = data
        const offset = (currentPage - 1) * totalPerPage
        const currentInvoices = items.slice(offset, (offset + totalPerPage))

        this.setState({ currentInvoices, currentPage, totalPages })
    }

    /**
     * onChanged.
     * 
     * @param {*} e 
     */
    onChanged = (e) => {
        try {
            //Get input value
            let search = ((e && e.target) ? e.target.value.trim().toLowerCase() : "")

            //Get props valies
            let { alls } = this.props

            //Search invoices
            let searchedList = alls
            if (search !== "") {
                searchedList = alls.filter((ox) => {
                    let { bill_price, bill_reference, created_at, payment_at } = ox
                    let ox_str = `${bill_reference} ${bill_price}`
                    ox_str += ` ${moment.unix(created_at).format("DD MMM YYYY")}`
                    // ox_str += ` ${(payment_at && (payment_at !== 0)) ? moment.unix(payment_at).format("DD MMM YYYY") : ""}`

                    return ox_str.trim().toLowerCase().includes(search)
                })
            }

            //Update state and launch search
            this.setState({ query: search }, () => {
                this.props.onSearched(searchedList)
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onFiltered.
     * 
     * @param {*} selected 
     * @param {*} option 
     */
    onFiltered = (selected, option) => {
        try {
            //Reset search views
            if (this.state.query !== "") {
                this.setState({ query: "" }, () => {
                    document.getElementById("search-invoices").value = ""
                })
            }

            //Launch filter
            this.props.onFiltered(selected)
        } catch (error) {
            console.log(error)
        }
    }

    onRandomColored = () => {
        return RANDOMS_COLORS[Math.floor(Math.random() * RANDOMS_COLORS.length)]
    }

    onPdfOpened = (pdfPath) => {
        try {
            window.open(pdfPath)
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { alls, items, filter, totalPerPage, itemCol, onChanged, onShowed, onRegenerated, showRegenerate } = this.props
        const { currentInvoices, currentPage, totalPages, query, userRole } = this.state

        return (
            <React.Fragment>
                <Col xl="12" sm="12" className="mt-2 mt-xl-0">
                    <Card className="shadow-none invoices-header">
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xl="7" md="12">
                                    <Filter
                                        items={[INVOICES_PAID, INVOICES_UNPAID]}
                                        selected={filter}
                                        onFiltered={this.onFiltered}
                                    />

                                    {(filter && filter.key && items) ? (
                                        <small className="d-block text-muted fw-bolder">
                                            {`${items.length} facture${(items.length > 1) ? "s" : ""} 
                                                ${filter.name.toLowerCase()}${(items.length > 1) ? "s" : ""}`}
                                        </small>
                                    ) : null}
                                </Col>
                                <Col xl="5" md="12" className="text-end">
                                    {(showRegenerate && onRegenerated && (userRole === USER_BILLER.key)) ? (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm my-1 center-ibox"
                                            onClick={() => onRegenerated()}
                                        >
                                            Générer les factures
                                            <i className="bx bxs-file ms-1" />
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>

                            <Row className="align-items-center mt-2">
                                <Col xl="3" className="p-2">
                                    <div className="search-box">
                                        <div className="position-relative">
                                            <Input
                                                id="search-invoices"
                                                type="text"
                                                className="form-control"
                                                placeholder="Rechercher..."
                                                onChange={(e) => this.onChanged(e)}
                                                disabled={(alls && (alls.length === 0))}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                        </div>
                                    </div>

                                    {(query && (query !== "") && items) ? (
                                        <small className="d-block text-muted fw-bolder fs-10">
                                            {`${items.length} facture${(items.length > 1) ? "s" : ""} 
                                                retrouvée${(items.length > 1) ? "s" : ""}`}
                                        </small>
                                    ) : null}
                                </Col>
                                <Col xl="9" className="p-2 text-end">
                                    <Paginator
                                        pageNeighbours={1}
                                        items={items}
                                        totalItems={items.length}
                                        itemsPerPage={totalPerPage}
                                        paginationClasses="mb-0 justify-content-end"
                                        onPageChanged={this.onPageChanged}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    <Row>
                        {(currentInvoices && (currentInvoices.length > 0) && currentInvoices.map((item, index) => (
                            <InvoiceItem
                                key={`invoice_item_${index}`}
                                onShowed={onShowed}
                                color={this.onRandomColored()}
                                status={getConstantObject(item.bill_status)}
                                onFileShowed={this.onPdfOpened}
                                col={itemCol}
                                {...item}
                            />
                        )))}
                        {((!currentInvoices) || (currentInvoices.length === 0)) ? (
                            <Col xl="12">
                                <Alert color="secondary"> Aucune facture à afficher </Alert>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </React.Fragment>
        )
    }

}
InvoiceList.propTypes = {
    alls: PropTypes.array,
    items: PropTypes.array,
    itemCol: PropTypes.number,
    totalPerPage: PropTypes.number,
    showRegenerate: PropTypes.bool
}
InvoiceList.defaultProps = {
    alls: [],
    items: [],
    itemCol: 3,
    totalPerPage: INVOICES_PER_PAGE,
    showRegenerate: true
}
export default InvoiceList


const InvoiceItem = ({ col, _id, bill_path, bill_reference, bill_price, bill_status, status, created_at, payment_at, color, onShowed, onFileShowed }) => (
    <React.Fragment>
        <Col xl={`${col}`} md="6" sm="12">
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <Col lg="12" sm="12">
                            <div className="invoice-content">
                                <span className="d-block mb-1">
                                    <small>Facture</small> {" "}
                                    <strong className="text-primary fs-13"> #{bill_reference}</strong>
                                </span>
                                <span className="d-block text-truncate mb-2 mb-lg-3 fs-13">
                                    <small>du</small> {" "}
                                    <strong className="fs-13">
                                        {`${moment.unix(created_at).format('DD MMM YYYY')}`}
                                    </strong>
                                </span>
                                <ul className="list-inline mb-1 d-flex align-items-center justify-content-between">
                                    <li className="list-inline-item me-1">
                                        <h5 className="fs-14" title="Montant">
                                            <i className="bx bx-money mb-1 text-muted" /> {" "}
                                            <span className="d-block">
                                                {bill_price}<small className="text-muted">(XOF)</small>
                                            </span>
                                        </h5>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                        <h5 className="fs-14">
                                            <i className={`bx ${status.icon} mb-1`} />{" "}
                                            {((bill_status === INVOICES_PAID.key) && payment_at && (payment_at !== 0)) ? (
                                                <span
                                                    className={`d-block text-${status.color} h-cursor`}
                                                    onClick={() => onShowed(_id, INVOICES_PAID_DETAILS)}
                                                >
                                                    {status.name}
                                                </span>
                                            ) : (
                                                <span
                                                    className={`d-block text-${status.color} h-cursor`}
                                                    onClick={() => onShowed(_id, INVOICES_PAID_NOTIFY)}
                                                >
                                                    {status.name}
                                                </span>
                                            )}
                                        </h5>
                                    </li>
                                </ul>
                                <ul className="list-inline mb-0 d-flex align-items-center justify-content-between">
                                    <li className="list-inline-item me-1">
                                        <h5
                                            className="fs-12 text-decoration-underline h-cursor"
                                            onClick={() => onShowed(_id, PARTNERS_SHOW)}
                                        >
                                            Voir le client
                                        </h5>
                                    </li>{" "}
                                    <li className="list-inline-item">
                                        <h5
                                            className="fs-12 text-decoration-underline h-cursor"
                                            onClick={() => onFileShowed(bill_path)}
                                        >
                                            Voir la facture
                                        </h5>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    </React.Fragment>
);