import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import custom components
import Loader from '../../components/common/Loader'

// import constants
import { PARTNERS_ADD, PARTNERS_EDIT, PASSWORD_MESSAGE, MAX_TEXT_INPUT, MIN_TEXT_INPUT } from "helpers/constants"


const PartnerForm = React.memo(({ opened, option, classes, error, loading, onClosed, onSubmited, onChecked, onChanged, onGenerated, partner }) => {

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                {`${(option === PARTNERS_EDIT) ? "Modifier" : "Ajouter"} un client contractuel`}
            </ModalHeader>
            <AvForm onValidSubmit={onSubmited}>
                <ModalBody>
                    <Row>
                        {(error && error !== "") ? (
                            <Col xl="12">
                                <Alert color="danger">{error}</Alert>
                            </Col>
                        ) : null}

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                name="name"
                                label="Nom"
                                type="text"
                                value={partner.name || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Le nom est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                type="email"
                                value={partner.email || ""}
                                validate={{
                                    email: {
                                        value: true,
                                        errorMessage: 'L\'email est incorrect.',
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: 'L\'email est obligatoire.',
                                    },
                                }}
                            />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                type="text"
                                name="phone"
                                label="Numéro de téléphone"
                                value={partner.phone || ""}
                                mask="(+22\9) 99 99 99 99"
                                maskChar="-"
                                tag={[Input, InputMask]}
                                validate={{
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Le numéro de téléphone a au moins 8 caractères.'
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: 'Le numéro de téléphone est obligatoire.',
                                    },
                                }}
                                helpMessage="Contact pour recevoir des sms de suivi et des appels en cas de besoin."
                            />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                type="text"
                                name="other_phone"
                                label="Autre contact"
                                value={partner.other_phone || ""}
                                mask="(+22\9) 99 99 99 99"
                                maskChar="-"
                                tag={[Input, InputMask]}
                                validate={{
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Le numéro de téléphone secondaire a au moins 8 caractères'
                                    }
                                }}
                                helpMessage="Contact servant de numéro de téléphone secondaire."
                            />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                name="ifu"
                                label="Numéro IFU"
                                type="text"
                                value={partner.ifu || ""}
                                validate={{
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le numéro IFU a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                            <AvField
                                name="username"
                                label="Identifiant"
                                type="text"
                                value={partner.username || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'L\'identifiant est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`L\'identifiant a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvField
                                name="address"
                                label="Addresse"
                                type="textarea"
                                rows="3"
                                value={partner.address || ""}
                                onChange={(e) => onChanged(e)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "L'addresse est obligatoire.",
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`L'addresse a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    },
                                    maxLength: {
                                        value: MAX_TEXT_INPUT,
                                        errorMessage: (`L'addresse a au plus ${MAX_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                            {(partner.address && (partner.address !== "")) ? (
                                <span className="badgecount badge bg-secondary">
                                    {" "}
                                    {`${partner.address.length}`} / {MAX_TEXT_INPUT}{" "}
                                </span>
                            ) : null}
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvGroup>
                                <Label> Mot de passe </Label>
                                <div className="input-group">
                                    <AvInput
                                        required={(option === PARTNERS_ADD)}
                                        name="password"
                                        label="Mot de passe"
                                        type="text"
                                        aria-describedby="password-help-block"
                                        aria-describedby="addon-generate-password"
                                        value={partner.password || ""}
                                    />
                                    <span
                                        id="addon-generate-password"
                                        className="input-group-text h-cursor"
                                        onClick={() => onGenerated()}
                                    >
                                        <i className="text-muted bx bx-xs bx-revision" />
                                    </span>
                                    <span id="password-help-block" className="form-text">
                                        {PASSWORD_MESSAGE}
                                    </span>
                                </div>
                            </AvGroup>
                        </Col>

                        <Col xl="12" className="mb-1">
                            <div className="form-group">
                                <div className={`custom-check${(partner.enabled) ? " active" : ""}`}>
                                    <div className="form-check form-switch form-switch-md check-control">
                                        <input
                                            name="enabled"
                                            type="checkbox"
                                            className="form-check-input"
                                            id="enabledSwitch"
                                            checked={partner.enabled || false}
                                            onChange={(e) => onChecked(e)}
                                        />
                                        <label className="form-check-label" htmlFor="enabledSwitch">
                                            <span className={`${(partner.enabled) ? "fw-bolder" : "fw-light"}`}>
                                                {(partner.enabled) ? "Actif" : "Inatif"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(loading) ? (
                        <Loader color={`${(option === PARTNERS_EDIT) ? "warning" : "info"}`} />
                    ) : (
                        <>
                            <button type="button" data-dismiss="modal" className="btn btn-light" onClick={() => onClosed()}>
                                Fermer
                            </button>
                            <button type="submit" className={`btn btn-${(option === PARTNERS_EDIT) ? "warning" : "info"} save-partner`}>
                                {(option == PARTNERS_EDIT) ? "Modifier" : "Ajouter"}
                            </button>
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    );

});
PartnerForm.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onChanged: PropTypes.func,
    onChecked: PropTypes.func,
    onClosed: PropTypes.func,
    onSubmited: PropTypes.func,
    onGenerated: PropTypes.func,
    partner: PropTypes.object
}
PartnerForm.defaultProps = {
    opened: false,
    classes: "partners-form-modal"
}
export default PartnerForm