import React from "react"
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import bootstrap components
import { Alert, Container, Label, Card, CardBody, Row, Col } from "reactstrap"

// import form components
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"

// import translation
import { withTranslation } from "react-i18next"

// import redux, actions
import { connect } from "react-redux"
import { authLogin, authReset } from "store/actions"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom components
import Loader from "components/common/Loader"

// import constants
import {
	APP_NAME, USER_AGENCY, USER_BILLER, USER_KEY, USER_MASTER, USER_ROOT, USER_SUPERVISOR
} from 'helpers/constants'

// import socket
import { socket } from "helpers/socket"

// TESTS CREDENTIALS
const USERS_CREDENTIALS = {
	root: "root", supervisor: "abdelfaical",
	agency: "monagence", biller: "sadia",
	password: "azerty123"
}


class Login extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			showPassword: false
		}
	}

	componentDidMount() {
		try {
			//Reset state
			this.props.onReset(true)

			//Disconnect socket
			if (socket.connected) {
				socket.off();
				socket.disconnect();
			}
		} catch (error) {
			console.error(error)
		}
	}

	componentDidUpdate(prevProps) {
		try {//Handle state changes
			if (!equal(prevProps, this.props)) {
				if (this.props.success) {
					let userRole = localStorage.getItem(USER_KEY) ? JSON.parse(localStorage.getItem(USER_KEY)).role : ""
					let redirectionLink = (userRole === USER_AGENCY.key) ? "/agency-orders" : "/dashboard"
					toast.success(
						"Vous êtes connecté ! Vous pouvez accéder à votre espace de travail.",
						{
							onClose: () => this.props.history.push(redirectionLink)
						}
					)
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On submit event
	 * 
	 * @param {*} e 
	 * @param {*} values 
	 */
	onSubmited = (e, values) => {
		e.preventDefault();//Prevent default behaviour

		try {//Launch login
			this.props.onLogin({ ...values })
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 * On password toggled.
	 * 
	 */
	onPasswordToggled = () => {
		this.setState(prevState => ({
			showPassword: !prevState.showPassword,
		}))
	}


	render() {
		const { error, loading, success } = this.props
		const { showPassword } = this.state

		return (
			<React.Fragment>
				<Helmet>
					<title>{APP_NAME} | Connexion</title>
				</Helmet>

				<Container fluid className="p-0">
					<div className="mb-4">
						<h5 className="text-dark fw-bold mb-0">
							{APP_NAME} - Administration
						</h5>
						<p className="text-secondary mb-0">
							Bienvenue, connectez-vous à votre compte.
						</p>
					</div>

					<AvForm onValidSubmit={this.onSubmited} className="form-horizontal">
						{(error && error != "") ? (
							<Alert color="danger">{error}</Alert>
						) : null}

						<div className="mb-3">
							<AvField
								type="text"
								name="username"
								label="Identifiant"
								className="form-control"
								placeholder="Entrez votre identifiant"
								validate={{
									required: {
										value: true,
										errorMessage: 'L\'identifiant est obligatoire.',
									},
								}}
							/>
						</div>

						<div className="mb-3">
							<AvGroup>
								<Label> Votre mot de passe </Label>
								<div className="input-group">
									<AvInput
										type={`${(showPassword) ? "text" : "password"}`}
										name="password"
										className="password-control form-control"
										placeholder="Entrez votre mot de passe"
										aria-label="toggle-pwd"
										aria-describedby="toggle-pwd-input-addon"
										required
									/>
									<span
										id="toggle-pwd-input-addon"
										className="toggle-password-btn input-group-text h-cursor"
										onClick={this.onPasswordToggled}
									>
										<i className={`text-muted bx bx-xs bxs-${(showPassword) ? "hide" : "show"}`} />
									</span>
									<AvFeedback>Le mot de passe est obligatoire.</AvFeedback>
								</div>
							</AvGroup>
						</div>

						<div className="mb-3">
							<AvField
								name="role"
								label="Profil à connecter"
								className="form-select"
								type="select"
								validate={{
									required: {
										value: true,
										errorMessage: 'Le profil à connecter est obligatoire.',
									},
								}}
							>
								<option value="">-- Sélectionner un profil --</option>
								{[USER_MASTER, USER_SUPERVISOR, USER_BILLER, USER_AGENCY, USER_ROOT].map((item, index) => (
									<option key={`sector_${index}`} value={item.key}>
										{item.name}
									</option>
								))}
							</AvField>
						</div>

						<div className="mt-4 d-grid">
							{loading ? (
								<Loader color="primary" />
							) : success ? (
								<Alert color="primary">Redirection...</Alert>
							) : (
								<button className="btn btn-primary btn-block" type="submit">
									Se connecter
								</button>
							)}
						</div>
					</AvForm>
				</Container>
			</React.Fragment>
		)
	}

}
Login.propTypes = {
	t: PropTypes.any,
	history: PropTypes.any,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.string,
	onLogin: PropTypes.func,
	onReset: PropTypes.func,
}
const mapStateToProps = state => ({
	error: state.auth.error,
	success: state.auth.success,
	loading: state.auth.loading,
})
const mapDispatchToProps = dispatch => ({
	onLogin: data => dispatch(authLogin(data)),
	onReset: all => dispatch(authReset(all)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Login)))