import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { addUser, editUser, listUsers, resetUsers, setUsers } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom components
import FactorTable from './FactorTable'
import FactorForm from './FactorForm'

// import constants
import {
    APP_NAME, FACTORS_ADD, FACTORS_EDIT, FACTORS_LIST, USER_AGENCY, USER_AGENT, USER_FACTOR
} from 'helpers/constants'

// import utilities
import {
    formatPhoneNumberForDisplay, formatPhoneNumberForRequest, generateRandomCode, getConstantObject
} from 'helpers/utilities'


class Factors extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formVisible: false,
            cardVisible: false
        }
    }

    componentDidMount() {
        try {// Load factors
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetFactors } = this.props

                if (success) {
                    // Close modals
                    if ([FACTORS_ADD, FACTORS_EDIT].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === FACTORS_ADD) {
                        toast.success("Nouveau facteur ajouté avec succès.")
                    } else if (option === FACTORS_EDIT) {
                        toast.success("Un facteur mis à jour avec succès.")
                    }

                    //Reset global state
                    onResetFactors(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetFactors(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On generated.
     * 
     */
    onGenerated = () => {
        try {
            //Get props
            let { factor, onSetFactors } = this.props

            //Store values
            onSetFactors({
                user: { ...factor, password: generateRandomCode(8, true) }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { factor, onSetFactors } = this.props

            //Store values
            onSetFactors({
                user: { ...factor, [name]: checked }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            //Get props
            let { factor, onSetFactors } = this.props

            //Store values
            onSetFactors({
                user: { ...factor, [name]: selected }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddFactor, onEditFactor, option, factor } = this.props

            switch (option) {
                case FACTORS_ADD:
                    if (factor.selected_vehicle && factor.selected_agency) {
                        onAddFactor({
                            firstname: values.firstname.trim(),
                            lastname: values.lastname.trim(),
                            phone: formatPhoneNumberForRequest(values.phone.trim()),
                            username: values.username.trim(),
                            password: values.password.trim(),
                            vehicle_type: factor.selected_vehicle.key,
                            agency_id: factor.selected_agency._id,
                            enabled: factor.enabled
                        }, option)
                    }
                    break;
                case FACTORS_EDIT:
                    if (factor.selected_vehicle && factor.selected_agency) {
                        onEditFactor({
                            firstname: values.firstname.trim(),
                            lastname: values.lastname.trim(),
                            phone: formatPhoneNumberForRequest(values.phone.trim()),
                            username: values.username.trim(),
                            password: values.password.trim(),
                            vehicle_type: factor.selected_vehicle.key,
                            agency_id: factor.selected_agency._id,
                            enabled: factor.enabled
                        }, factor._id, option)
                    }
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetFactors, onResetFactors } = this.props

            //Reset state
            onResetFactors(false)

            //Init state
            onSetFactors({ user: { enabled: true }, option: FACTORS_ADD })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetFactors, onResetFactors } = this.props

            //Reset state
            onResetFactors(false)

            //Init state
            onSetFactors({
                user: {
                    ...item,
                    phone: formatPhoneNumberForDisplay(item.phone),
                    selected_vehicle: getConstantObject(item.vehicle_type),
                    selected_agency: item.agency ? item.agency : null
                },
                option: FACTORS_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On loaded.
    * 
    */
    onLoaded = () => {
        try {// Load factors
            let { factors, onResetFactors, onListFactors } = this.props
            if (factors.length > 0) onResetFactors(true)
            onListFactors(FACTORS_LIST)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, agencies, factors, factor, error, option, loading } = this.props
        const { formVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Factors")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Factors")}
                            subtitle={(factors.length > 0) ? (`(${factors.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === FACTORS_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === FACTORS_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <FactorTable
                                        items={factors}
                                        onAdding={this.onAdding}
                                        onEditing={this.onEditing}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(formVisible) ? (
                    <FactorForm
                        opened={formVisible}
                        option={[FACTORS_ADD, FACTORS_EDIT].includes(option) ? option : ""}
                        error={[FACTORS_ADD, FACTORS_EDIT].includes(option) ? error : ""}
                        loading={[FACTORS_ADD, FACTORS_EDIT].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        onSubmited={this.onSubmited}
                        onChecked={this.onChecked}
                        onSelected={this.onSelected}
                        onGenerated={this.onGenerated}
                        factor={factor}
                        agencies={agencies.filter((item) => (item.enabled && item.contact_point))}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Factors.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    factors: PropTypes.array,
    factor: PropTypes.object,
    onListactors: PropTypes.func,
    onSetFactors: PropTypes.func,
    onResetFactors: PropTypes.func,
    onAddFactor: PropTypes.func,
    onEditFactor: PropTypes.func
}
const mapStateToProps = state => ({
    factors: state.utilisateurs.factors,
    agencies: state.utilisateurs.agencies,
    factor: state.utilisateurs.user,
    error: state.utilisateurs.error,
    option: state.utilisateurs.option,
    success: state.utilisateurs.success,
    loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetFactors: (data) => dispatch(setUsers(data)),
    onResetFactors: (all) => dispatch(resetUsers(all)),
    onListFactors: (option) => dispatch(listUsers(USER_FACTOR, USER_AGENCY, false, false, option)),
    onAddFactor: (data, option) => dispatch(addUser(data, USER_FACTOR, option)),
    onEditFactor: (data, id, option) => dispatch(editUser(data, id, USER_FACTOR, true, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Factors)))