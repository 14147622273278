import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import store and actions
import { connect } from 'react-redux'
import { resetOrders, setOrders, transfertOrder } from 'store/actions'

// import QRCode
import QRCode from "react-qr-code";

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"

// import constants
import { ORDERS_ACCEPT, ORDERS_SHOW, ORDERS_TRANSFERT } from 'helpers/constants'


class OrderForward extends React.Component {

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, order, onClosed } = this.props
                if (success && (option === ORDERS_ACCEPT)) {
                    // Display toasts
                    toast.success(`Transfert accepté avec succès.`)

                    //Close modal
                    onClosed("forwardVisible", false)
                } else if (order.transfert && order.transfert.forwarded) {
                    // Display toasts
                    toast.success(`Transfert effectué avec succès.`)

                    //Close modal
                    onClosed("forwardVisible", false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(false)
        } catch (error) {
            console.error(error)
        }
    }

    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            let { order, option, onTransfertOrder } = this.props

            switch (option) {
                case ORDERS_TRANSFERT:
                    onTransfertOrder({
                        order_id: order._id
                    }, false, option)
                    break;
                case ORDERS_ACCEPT:
                    onTransfertOrder({
                        forward_code: values.accept_code.trim()
                    }, true, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, order, onClosed } = this.props
        const { code, created_at, start_point, delivery_point, current_status, transfert } = order

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalHeader tag="h4">
                        {
                            (option === ORDERS_TRANSFERT) ? "Initier le transfert d'une course" :
                                (option === ORDERS_ACCEPT) ? "Accepter un tranfert de course" :
                                    ""
                        }
                        {(created_at && code) ? (
                            <span className="d-block fs-12 text-secondary">
                                Course {" "}
                                <strong> #{code} </strong>
                                du {" "}
                                <strong>
                                    {`${moment.unix(created_at).format('DD MMM YYYY HH:mm')}`}
                                </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            {(error && (error !== "") && ([ORDERS_TRANSFERT, ORDERS_ACCEPT].includes(option))) ? (
                                <Col xl="12">
                                    <Alert color="danger">{error}</Alert>
                                </Col>
                            ) : null}

                            {((option === ORDERS_TRANSFERT) && (order && order._id)) ? (
                                <Col sm="12" className="mb-1">
                                    {(transfert && transfert.forward_code && transfert.ttl) ? (
                                        <div className="text-center px-3">
                                            <h5>
                                                Transfert de la course initialisé avec succès.
                                            </h5>
                                            <span className="d-block fw-bolder fs-3 mb-2">
                                                {transfert.forward_code}
                                            </span>
                                            <QRCode
                                                value={transfert.forward_code}
                                                title="Code de transfert"
                                                size={200}
                                            />
                                            <small className="d-block fw-bold text-danger mt-2">
                                                Le code de transfert est valide pour {" "}
                                                <strong>
                                                    {`${moment.duration(transfert.ttl, "s").asHours()}h`}
                                                </strong>
                                            </small>
                                            <span className="mt-1">
                                                Veuillez communiquer le code ci-dessus au destinataire du transfert
                                                afin de complèter le transfert de la course !
                                            </span>
                                        </div>
                                    ) : (
                                        <h5 className="text-center px-3 mb-0">
                                            Voulez-vous initialiser le transfert de cette course
                                            en générant un code de transfert ?
                                        </h5>
                                    )}
                                </Col>
                            ) : null}

                            {(option === ORDERS_ACCEPT) ? (
                                <Col sm="12" className="mb-1">
                                    <AvGroup>
                                        <AvField
                                            name="accept_code"
                                            type="text"
                                            label="Code de transfert"
                                            placeholder="0000#00-000000"
                                            minLength="14"
                                            maxLength="14"
                                            required
                                            aria-describedby="accept-help-block"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Le code de transfert est obligatoire.',
                                                },
                                                maxLength: {
                                                    value: 14,
                                                    errorMessage: 'Le code de transfert a 14 caractères.',
                                                },
                                                minLength: {
                                                    value: 14,
                                                    errorMessage: 'Le code de transfert a 14 caractères.',
                                                }
                                            }}
                                        />
                                        <span id="accept-help-block" className="form-text">
                                            Pour accepter une course par transfert, veuillez saisir le code de
                                            transfert en respectant le format <strong>0000#00-000000</strong>.
                                        </span>
                                    </AvGroup>
                                </Col>
                            ) : null}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {(loading && ([ORDERS_TRANSFERT, ORDERS_ACCEPT].includes(option))) ? (
                            <Loader color="primary" />
                        ) : (
                            <>
                                <button
                                    type="button"
                                    data-dismiss="modal" className="btn btn-light"
                                    onClick={() => onClosed("forwardVisible", false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary save-forward"
                                    disabled={(transfert && transfert.forward_code && transfert.ttl)}
                                >
                                    {(option === ORDERS_TRANSFERT) ? "Continuer" : "Accepter"}
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </AvForm>
            </Modal>
        );
    }

}
OrderForward.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    order: PropTypes.object
}
OrderForward.defaultProps = {
    opened: false,
    classes: "order-forward-modal"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onTransfertOrder: (data, accept, option) => dispatch(transfertOrder(data, accept, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderForward)