import React from 'react'
import PropTypes from 'prop-types'

// import components
import {
    Alert, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { listCustomers, resetCustomers, setCustomers } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// Form Mask
import InputMask from "react-input-mask"

// import custom components
import Selecter from "components/common/Selecter"
import PayButton from 'components/common/PayButton'

// import constants
import { CUSTOMER_CASUAL, CUSTOMER_PARTNER, PARTNERS_LIST } from 'helpers/constants'


const CustomerForm = React.memo(({ opened, option, classes, error, loading, success, order, partners, onClosed, onChanged, onSelected, onPayed, onSwitched }) => {

    const { customer_name, customer_contact, selected_customer, customer_type, cost } = order

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                Donneur d'ordre
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col xl="12" className="mb-2">
                            <p className="fs-13 fw-light">
                                Avant de continuer, vous devez renseigner les informations du donneur d'ordre.
                            </p>
                        </Col>
                    </Row>

                    {(customer_type === CUSTOMER_PARTNER) ? (
                        <Row>
                            <FormGroup>
                                <Label htmlFor="selected_customer">
                                    Sélectionnez le client
                                </Label>
                                <Selecter
                                    onSelected={onSelected}
                                    options={partners}
                                    loading={(loading && (option === PARTNERS_LIST))}
                                    id="selected_customer"
                                    name="selected_customer"
                                    placeholder="..."
                                    valueAttribut="_id"
                                    labelAttribut="name"
                                />
                            </FormGroup>
                        </Row>
                    ) : (
                        <Row>
                            <Col xl="12" className="mb-3">
                                <FormGroup>
                                    <Label htmlFor="customer_name">
                                        Nom et Prénom(s)
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="customer_name"
                                        name="customer_name"
                                        onChange={onChanged}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xl="12">
                                <FormGroup>
                                    <Label htmlFor="customer_contact">
                                        Contact
                                    </Label>
                                    <InputMask
                                        type="text"
                                        className="form-control"
                                        mask="(+22\9) 99 99 99 99"
                                        maskChar="-"
                                        id="customer_contact"
                                        name="customer_contact"
                                        onChange={onChanged}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col xl="12" className="mt-3">
                            <span className="customer-switches">
                                <span
                                    className={`customer-switch switch-casual${(customer_type === CUSTOMER_CASUAL) ? " active" : ""}`}
                                    onClick={() => onSwitched(CUSTOMER_CASUAL, "customer_type")}
                                >
                                    Client occasionnel
                                </span>
                                <span
                                    className={`customer-switch switch-contractual${(customer_type === CUSTOMER_PARTNER) ? " active" : ""}`}
                                    onClick={() => onSwitched(CUSTOMER_PARTNER, "customer_type")}
                                >
                                    Client contractuel
                                </span>
                            </span>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button" data-dismiss="modal" className="btn btn-light"
                    onClick={() => onClosed("customerVisible", false)}
                >
                    Fermer
                </button>
                {(customer_type === CUSTOMER_PARTNER) ? (
                    <button
                        type="button" className="btn btn-primary save-customer"
                        onClick={() => onPayed("")}
                    >
                        Continuer
                    </button>
                ) : (
                    <PayButton
                        amount={((process.env.REACT_APP_ENV_PROD !== "false") ? cost.total : 1)}
                        onPayed={onPayed}
                        label="Payer et Continuer"
                        classes="btn btn-accent"
                    />
                )}
            </ModalFooter>
        </Modal>
    )

});
CustomerForm.defaultProps = {
    opened: false,
    classes: "order-customer-modal",
}
export default withRouter(withTranslation()(CustomerForm))
