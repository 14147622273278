import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import deep-equal
import equal from "deep-equal"

// import store and actions
import { connect } from 'react-redux'
import { repayOrder, resetOrders, setOrders } from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"

// import constants
import { MIN_TEXT_INPUT, ORDERS_PAYBACK } from 'helpers/constants'
import { AvField, AvForm } from 'availity-reactstrap-validation'


class OrderRefund extends React.Component {

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success && (option === ORDERS_PAYBACK)) {
                    onClosed("refundVisible", false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(false)
        } catch (error) {
            console.log(error)
        }
    }

    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            let { order, option, onRepayOrder } = this.props
            onRepayOrder({
                id: order._id,
                code: order.code,
                data: {
                    reason: values.refund_reasons.trim()
                }
            }, option)
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, order, onClosed } = this.props
        const { code, created_at, current_status, payment, nature } = this.props.order

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalHeader tag="h4">
                        Rembourser une course
                        {(created_at && code) ? (
                            <span className="d-block fs-12 text-secondary">
                                Course {" "}
                                <strong> #{code} </strong>
                                du {" "}
                                <strong>
                                    {`${moment.unix(created_at).format('DD MMM YYYY HH:mm')}`}
                                </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        {(order && order._id) ? (
                            <Row>
                                {(error && (error !== "") && (option === ORDERS_PAYBACK)) ? (
                                    <Col xl="12">
                                        <Alert color="danger">{error}</Alert>
                                    </Col>
                                ) : null}

                                <Col xl="12" className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span>
                                            <small className="text-muted text-decoration-underline me-1">Coût</small>
                                            <strong> {payment.price} </strong>(XOF)
                                        </span>
                                        <span>
                                            <small className="text-muted text-decoration-underline ms-1">Nature</small>
                                            <strong> {nature} </strong>
                                        </span>
                                    </div>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <h5 className="my-2 text-center px-3">
                                        Lancer le remboursement de cette course ?
                                        <small className="d-block text-danger mt-1">
                                            *Une course remboursée ne sera plus prise en compte dans les courses à traiter
                                        </small>
                                    </h5>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <AvField
                                        name="refund_reasons"
                                        label="Motif de remboursement"
                                        type="textarea"
                                        rows="3"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Ce champs est obligatoire.",
                                            },
                                            minLength: {
                                                value: MIN_TEXT_INPUT,
                                                errorMessage: (`Ce champs a au moins ${MIN_TEXT_INPUT} caractères.`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </ModalBody>

                    <ModalFooter>
                        {(loading && (option === ORDERS_PAYBACK)) ? (
                            <Loader color="danger" />
                        ) : (
                            <>
                                <button
                                    type="button"
                                    data-dismiss="modal" className="btn btn-light"
                                    onClick={() => onClosed("refundVisible", false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="submit" className="btn btn-danger save-refund"
                                >
                                    Continuer
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

}
OrderRefund.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    order: PropTypes.object,
}
OrderRefund.defaultProps = {
    opened: false,
    classes: "order-refund-modal"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onRepayOrder: (data, option) => dispatch(repayOrder(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderRefund)