import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Row, Col, Alert, Input, Media } from "reactstrap"

//SimpleBar
import SimpleBar from "simplebar-react"

// import store and actions
import { connect } from 'react-redux'
import { listUsers, resetUsers, setUsers } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import custom common components
import Loader from 'components/common/Loader'

// import constants
import {
    AGENTS_LIST, APP_NAME, USER_AGENCY, USER_AGENT, USER_FACTOR
} from 'helpers/constants'
import { getConstantObject } from 'helpers/utilities'


class AgentSelector extends React.Component {

    constructor(props) {
        super(props)

        this.state = { query: "" }
    }

    componentDidMount() {
        try {//Load agents list
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {
            // Reset state
            this.props.onResetAgents(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On Loaded.
    * 
    */
    onLoaded = () => {
        try {// Launch request to get list
            this.props.onListAgents(AGENTS_LIST)
        } catch (error) {
            console.error(error)
        }
    }

    onSearched = () => {
        let { agents } = this.props
        let { query } = this.state
        try {
            return ((query === "") ? agents : agents.filter((ax) => (
                (`${ax.name}`).toLowerCase().includes(query)
            )))
        } catch (error) {
            console.error(error)
            return agents
        }
    }

    onChanged = (e) => {
        try {
            this.setState({
                query: ((e && e.target) ? e.target.value.trim().toLowerCase() : "")
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On Selected.
    * 
    * @param {*} item 
    */
    onSelected = (item) => {
        try {
            // console.log({ item }, "ON SELECTED")
            let { onSetAgents, onResetAgents } = this.props
            onResetAgents(false)
            onSetAgents({ user: { ...item } })
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { t, agents, agent, loading, success, error, option } = this.props

        return (
            <React.Fragment>
                <SimpleBar style={{ maxHeight: "500px" }}>
                    <div data-simplebar style={{ height: "500px" }}>
                        <div className="agents-selector">
                            <div className="selector-header mb-3">
                                <h5 className="fs-14 mb-0">
                                    <strong>
                                        Utilisateurs
                                        {(agents.length > 0) ? `(${agents.length})` : ""}
                                    </strong>
                                </h5>
                            </div>
                            <div className="selector-searcher mb-3">
                                <div className="search-box">
                                    <div className="position-relative">
                                        <Input
                                            id="search-agents"
                                            type="text"
                                            className="form-control"
                                            placeholder="Rechercher..."
                                            onChange={(e) => this.onChanged(e)}
                                            disabled={(agents && (agents.length === 0))}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                    </div>
                                </div>
                            </div>

                            {(loading && (option === AGENTS_LIST)) ? (
                                <Loader color="primary" />
                            ) : ((error !== "") && (option === AGENTS_LIST)) ? (
                                <Alert color="danger" className="d-flex align-items-center justify-content-between mb-0">
                                    {error}{" "}
                                    <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                        Réessayer
                                    </Link>
                                </Alert>
                            ) : (agents && (agents.length > 0)) ? (
                                <div className="agents-list mb-1">
                                    <ul>
                                        {this.onSearched().map((item, index) => (
                                            <li
                                                key={`agent_item_${index}`}
                                                onClick={() => this.onSelected(item)}
                                                className={`${(agent._id === item._id) ? "active" : ""}`}
                                            >
                                                <Media>
                                                    <div className="align-self-center me-2">
                                                        {item.avatar ? (
                                                            <div className="mx-auto">
                                                                <img className="rounded-circle avatar-xs" src={item.avatar} />
                                                            </div>
                                                        ) : (
                                                            <div className="avatar-xs mx-auto">
                                                                <span className={`avatar-title rounded-circle bg-soft bg-secondary text-secondary fw-bold fs-7`}>
                                                                    {`${item.name.charAt(0).toUpperCase() || ""}${item.name.charAt(1).toUpperCase() || ""}`}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <Media className="overflow-hidden align-self-center" body>
                                                        <h5 className="text-truncate fs-14 mb-0">
                                                            {item.name}
                                                        </h5>
                                                        {(item.role === USER_FACTOR.key) ? (
                                                            <p className="text-truncate mb-0 fs-12">
                                                                {USER_FACTOR.name} {" "} {" "}
                                                                <i className={`${getConstantObject(item.vehicle_type, "icon")} text-muted`} />
                                                            </p>
                                                        ) : ((item.role === USER_AGENCY.key)) ? (
                                                            <p className="text-truncate mb-0 fs-12">
                                                                {(item.contact_point) ? "Point contact" : "Point relais"}
                                                            </p>
                                                        ) : null}
                                                    </Media>
                                                </Media>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <p className="text-muted text-center bg-light p-2 mb-0">
                                    Aucun utilisateur à afficher
                                </p>
                            )}
                        </div>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }

}
AgentSelector.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    onListAgents: PropTypes.func,
}
const mapStateToProps = state => ({
    agents: state.utilisateurs.agents,
    agent: state.utilisateurs.user,
    error: state.utilisateurs.error,
    option: state.utilisateurs.option,
    success: state.utilisateurs.success,
    loading: state.utilisateurs.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetAgents: (data) => dispatch(setUsers(data)),
    onResetAgents: (all) => dispatch(resetUsers(all)),
    onListAgents: (option) => dispatch(listUsers(USER_AGENT, "", true, false, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(AgentSelector)))