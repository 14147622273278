import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, {
    CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM
} from 'components/lists/CustomDatatable';

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import utilities
import { formatExportedFilename, formatPhoneNumberForDisplay } from 'helpers/utilities';


const LoggingTable = ({ items, masters }) => {

    const columns = [
        {
            prop: "created_at",
            title: "Date",
            filterable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {moment.unix(row.created_at).format("DD MMM YYYY HH:mm")}
                    </span>
                );
            }
        },
        {
            prop: "method",
            title: "Action",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span>
                        {row.method}
                    </span>
                );
            }
        },
        {
            prop: "path",
            title: "Chemin",
            cellProps: {
                className: row => "text-center"
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {row.path}
                    </span>
                );
            }
        },
        {
            prop: "master",
            title: "Super Admin",
            filterable: true,
            cellProps: {
                className: row => "text-center"
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {(row.master && row.master.name) ? row.master.name : "Root"}
                    </span>
                );
            }
        },
    ];

    const onFilter = {
        created_at: (value: any) => {
            return moment.unix(value).format("DD MMM YYYY HH:mm")
        },
        master: (value: any) => {
            return ((value && value.name) ? value.name : "Root")
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        classes={CLASSES}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} logs` }}
                        exportOptions={{ exportAction: false, label: "" }}
                        leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                        onFilter={onFilter}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
LoggingTable.propTypes = {
    items: PropTypes.array,
    masters: PropTypes.array,
}
LoggingTable.defaultProps = {
    items: [],
    masters: []
}
export default LoggingTable