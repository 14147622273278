import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorOrders, setOrders, successOrders } from "./actions"
import { isUnauthorized, listUsers } from "store/actions"

// import constants
import {
    ORDERS_ATTACH, ORDERS_INSERT, ORDERS_SELECT, ORDERS_GET, ORDERS_UPDATE, ORDERS_FIND,
    ORDERS_AGENTS, ORDERS_RETRIEVE, ORDERS_REFUND, ORDERS_COMPLETE, ORDERS_FORWARD, ORDERS_CALCULATE
} from "./constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { formatEventsWithType, formatFactorsForDashboard, formatOrdersWithStatus, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import { attachOrder, insertOrder, updateOrder, selectOrders, getOrder, forwardOrder, findOrder, refundOrder, completeOrder } from "services/orderService"
import { selectUsers } from "services/userService"
import { calculatePricing } from "services/pricingService"

// import dummies
import { generateCustomers, generateEvents, generateFactors, generateIssues, generateOrders } from "helpers/dummies"


// ERRORS CUSTOMS MESSAGES
const ORDERS_ERRORS = {
    "CALCULATE": {},
    "INSERT": {},
    "ATTACH": {
        409: "La course a déjà été assignée.",
    },
    "GET": {
        400: "La course recherchée n'existe pas."
    },
    "AGENTS": {
        403: "Aucun utilisateur retrouvé."
    },
    "IN_FORWARD": {
        401: "Echec de l'opération. Le code de transfert fourni est incorrect.",
        403: "Echec de l'opération. Vous êtes déjà en charge de la course à transférer.",
        404: "Echec de l'opération. Le code de transfert fourni est soit expiré, déjà validé ou inexistant."
    },
    "OUT_FORWARD": {
        400: "Echec de l'opération. Une course remboursée ne peut plus être traitée."
    },
    "COMPLETE": {
        406: "Le code de livraison est incorrect.",
        409: "La course ne peut pas être terminée alors qu'elle n'a pas été démarée."
    },
    "SEARCHES": {
        404: "Aucune course retrouvée",
        400: "Une erreur est survenue au cours de l'opération.",
        409: "L'opération demandée a déjà été effectuée",
        500: "Une erreur est survenue au cours de l'opération.",
        "refund": {
            404: "La course à rembourser n'a pas été retrouvée",
            400: "La course ne pas être remboursée",
            409: "La course a déjà été remboursée",
            500: "Le remboursement de la course a échoué. Une erreur est survenue au cours de l'opération."
        }
    }
}


function* onSelectOrders({ payload }) {
    try {
        const response = yield call(selectOrders, payload.filter, payload.for_user)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ orders: (response.data ? response.data : []) }, payload.option))
        } else {
            console.error(response, "SELECT ORDERS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "SELECT ORDERS ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onGetOrder({ payload }) {
    try {
        const response = yield call(getOrder, payload.id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({
                order: (response.data ? response.data : {})
            }, payload.option))
        } else {
            console.error(response, "GET ORDER ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.GET)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "GET ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onCalculateOrder({ payload }) {
    try {
        const response = yield call(calculatePricing, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ cost: (response.data ? response.data : {}) }, payload.option))
        } else {
            console.error(response, "CALCULATE ORDER PRICING ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.CALCULATE)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "CALCULATE ORDER PRICING ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onInsertOrder({ payload }) {
    try {
        let { data, option } = payload

        let form_data = new FormData();
        form_data.append('start_lat', data.start_lat);
        form_data.append('start_lng', data.start_lng);
        form_data.append('start_address', data.start_address);
        form_data.append('start_contact', data.start_contact);
        form_data.append('delivery_lat', data.delivery_lat);
        form_data.append('delivery_lng', data.delivery_lng);
        form_data.append('delivery_address', data.delivery_address);
        form_data.append('delivery_contact', data.delivery_contact);
        form_data.append('nature', data.nature);
        form_data.append('description_text', data.description_textual);
        form_data.append('is_audio_description', data.is_audio);
        form_data.append('customer_contact', data.customer_contact);
        form_data.append('customer_name', data.customer_name);
        form_data.append('customer_id', data.customer_id);
        form_data.append('delivery_auth', data.delivery_auth);
        form_data.append('round_trip', data.round_trip);
        form_data.append('price', data.price);
        form_data.append('transaction_id', data.transaction_id);

        if (data.is_audio) {
            form_data.append('description_file', data.description_file, data.description_filename);
        }

        const response = yield call(insertOrder, form_data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let { customer, customer_detail, customer_id } = response.data
            let formated_response = response.data ? {
                ...response.data,
                customer: (customer_id && (customer_id !== "")) ? customer_detail : customer
            } : {}
            yield put(successOrders({ order: formated_response }, option))
        } else {
            console.error(response, "INSERT ORDER ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.INSERT)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "INSERT ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onUpdateOrder({ payload }) {
    try {
        console.log({ payload }, "UPDATE ORDER PAYLOAD")
    } catch (error) {
        console.error(error, "UPDATE ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onAttachOrder({ payload }) {
    try {
        const response = yield call(attachOrder, payload.id, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectOrders, payload.type)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                const item = yield call(getOrder, payload.id)
                if (isSuccessfulRequest(item.status, item.statusText)) {
                    yield put(successOrders({
                        orders: (list.data ? list.data : []),
                        order: (item.data ? item.data : {})
                    }, payload.option))
                } else {
                    console.error(item, "ATTACH ORDER ERROR")
                    let { message, unauth } = errorMessage(item, ORDERS_ERRORS.GET)
                    yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                    if (unauth) yield put(isUnauthorized())
                }
            } else {
                console.error(list, "ATTACH ORDER ERROR")
                let { message, unauth } = errorMessage(list)
                yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            console.error(response, "ATTACH ORDER ERROR")
            let { message, unauth } = errorMessage(response, ORDERS_ERRORS.ATTACH)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "ATTACH ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onCompleteOrder({ payload }) {
    try {
        let { id, data, option } = payload
        const response = yield call(completeOrder, id, data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectOrders, "current_user")
            if (isSuccessfulRequest(list.status, list.statusText)) {
                const item = yield call(getOrder, id)
                if (isSuccessfulRequest(item.status, item.statusText)) {
                    yield put(successOrders({
                        orders: (list.data ? list.data : []),
                        order: (item.data ? item.data : {})
                    }, payload.option))
                } else {
                    console.error(item, "COMPLETE ORDER ERROR")
                    let { message, unauth } = errorMessage(item, ORDERS_ERRORS.GET)
                    yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                    if (unauth) yield put(isUnauthorized())
                }
            } else {
                console.error(list, "COMPLETE ORDER ERROR")
                let { message, unauth } = errorMessage(list)
                yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            console.error(response, "COMPLETE ORDER ERROR")
            let { message, unauth } = errorMessage(response, ORDERS_ERRORS.COMPLETE)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "COMPLETE ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onForwardOrder({ payload }) {
    try {
        let { data, accept, option } = payload
        const response = yield call(forwardOrder, data, accept)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            if (accept) {
                const list = yield call(selectOrders, "current_user")
                if (isSuccessfulRequest(list.status, list.statusText)) {
                    yield put(successOrders({ orders: (list.data ? list.data : []) }, option))
                } else {
                    console.error(list, "END FORWARD ORDER ERROR")
                    let { message, unauth } = errorMessage(list)
                    yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                    if (unauth) yield put(isUnauthorized())
                }
            } else {
                yield put(successOrders({
                    transfert: (response.data ? response.data : {})
                }, option))
            }
        } else {
            console.error(response, "FORWARD ORDER ERROR")
            let errors_messages = (accept ? ORDERS_ERRORS.IN_FORWARD : ORDERS_ERRORS.OUT_FORWARD)
            let { message, unauth } = errorMessage(response, errors_messages)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "FORWARD ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onRetrieveOrder({ payload }) {
    try {
        const response = yield call(getOrder, payload.data, "code")
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ orders: (response.data ? [response.data] : []) }, payload.option))
        } else {
            console.error(response, "RETRIEVE ORDER ERROR")
            let error = errorMessage(response, ORDERS_ERRORS.SEARCHES)
            yield put(errorOrders((error.unauth ? "" : error.message), (error.unauth ? "" : payload.option)))
            if (error.unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "RETRIEVE ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onFindOrders({ payload }) {
    try {
        let { start_date, end_date, customer_phone, partner } = payload.data
        const response = yield call(findOrder, start_date, end_date, customer_phone, partner)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successOrders({ orders: (response.data ? response.data : []) }, payload.option))
        } else {
            console.error(response, "FIND ORDERS ERROR")
            let error = errorMessage(response, ORDERS_ERRORS.SEARCHES)
            yield put(errorOrders((error.unauth ? "" : error.message), (error.unauth ? "" : payload.option)))
            if (error.unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "FIND ORDERS ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onRefundOrder({ payload }) {
    try {
        let { id, code, data, option } = payload
        const response = yield call(refundOrder, id, data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(getOrder, code, "code")
            if (isSuccessfulRequest(list.status, list.statusText)) {
                const item = yield call(getOrder, id)
                if (isSuccessfulRequest(item.status, item.statusText)) {
                    yield put(successOrders({
                        orders: (list.data ? [list.data] : []),
                        order: (item.data ? item.data : {})
                    }, option))
                } else {
                    console.error(item, "COMPLETE ORDER ERROR")
                    let { message, unauth } = errorMessage(item, ORDERS_ERRORS.GET)
                    yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
                    if (unauth) yield put(isUnauthorized())
                }
            } else {
                console.error(list, "REFUND ORDER ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            console.error(response, "REFUND ORDER ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.SEARCHES.refund)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "REFUND ORDER ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}

function* onSelectAgents({ payload }) {
    try {
        let { filter, enabled_only, option } = payload
        const response = yield call(selectUsers, filter.key)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let formated_list = (response.data ? enabled_only ? response.data.filter((item) => (item.enabled)) : response.data : [])
            formated_list = formated_list.map((item) => ({
                ...item,
                name: item.name ? item.name : (item.firstname && item.lastname) ? `${item.firstname} ${item.lastname}` : ""
            }))
            yield put(successOrders({ [filter.tag]: formated_list }, option))
        } else {
            console.error(response, "SELECT USERS ERROR")
            let { unauth, message } = errorMessage(response, ORDERS_ERRORS.AGENTS)
            yield put(errorOrders((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "SELECT USERS ERROR")
        yield put(errorOrders(errorMessage(error).message, payload.option))
    }
}


function* OrderSaga() {
    yield takeEvery(ORDERS_SELECT, onSelectOrders)
    yield takeEvery(ORDERS_INSERT, onInsertOrder)
    yield takeEvery(ORDERS_UPDATE, onUpdateOrder)
    yield takeEvery(ORDERS_ATTACH, onAttachOrder)
    yield takeEvery(ORDERS_FORWARD, onForwardOrder)
    yield takeEvery(ORDERS_AGENTS, onSelectAgents)
    yield takeEvery(ORDERS_GET, onGetOrder)
    yield takeEvery(ORDERS_FIND, onFindOrders)
    yield takeEvery(ORDERS_RETRIEVE, onRetrieveOrder)
    yield takeEvery(ORDERS_REFUND, onRefundOrder)
    yield takeEvery(ORDERS_COMPLETE, onCompleteOrder)
    yield takeEvery(ORDERS_CALCULATE, onCalculateOrder)
}

export default OrderSaga