import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import custom components
import Loader from "components/common/Loader"

// import utilities
import { formatPhoneNumberForDisplay, upperFirst } from "helpers/utilities"


const PartnerCard = React.memo(({ opened, option, classes, error, loading, onClosed, partner }) => {

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                <span className="fs-15">
                    Toutes les informations du client contractuel {" "}
                    <strong className="fs-14">@{partner.username}</strong>
                </span>
            </ModalHeader>
            <ModalBody>
                <Row className="mb-3">
                    <Col xl={3}>
                        <span className="text-muted fs-12">
                            Nom
                        </span>
                    </Col>
                    <Col xl={9}>
                        <span className="text-justify fs-14">
                            {upperFirst(partner.name)}
                        </span> {" "}
                        {partner.enabled ? (
                            <span className={`badge bg-soft bg-success text-success fw-bold fs-12`}>
                                Actif
                            </span>
                        ) : (
                            <span className={`badge bg-soft bg-secondary text-secondary fw-bold fs-12`}>
                                Inactif
                            </span>
                        )}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xl={3}>
                        <span className="text-muted fs-12">
                            Addresse
                        </span>
                    </Col>
                    <Col xl={9}>
                        <p className="text-justify mb-0 fs-14">
                            {upperFirst(partner.address)}
                        </p>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col xl={3}>
                        <span className="text-muted fs-12">
                            Contact(s)
                        </span>
                    </Col>
                    <Col xl={9}>
                        <span className="text-justify fs-14 fw-bolder">
                            {formatPhoneNumberForDisplay(partner.phone)}
                        </span>
                        {(partner.other_phone && partner.other_phone !== "") ? (
                            <>
                                {" "} - {" "}
                                <span className="text-justify fs-14 fw-light">
                                    {formatPhoneNumberForDisplay(partner.other_phone)}
                                </span>
                            </>
                        ) : null}
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col xl={3}>
                        <span className="text-muted fs-12">
                            Email
                        </span>
                    </Col>
                    <Col xl={9}>
                        <span className="text-justify fs-14">
                            {partner.email}
                        </span>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col xl={3}>
                        <span className="text-muted fs-12">
                            Identifiant
                        </span>
                    </Col>
                    <Col xl={9}>
                        <span className="text-justify fs-14">
                            {partner.username}
                        </span>
                    </Col>
                </Row>
                
                {(partner.ifu && (partner.ifu !== "")) ? (
                    <Row className="mb-1">
                        <Col xl={3}>
                            <span className="text-muted fs-12">
                                Numéro IFU
                            </span>
                        </Col>
                        <Col xl={9}>
                            <span className="text-justify fs-14">
                                {partner.ifu || ""}
                            </span>
                        </Col>
                    </Row>
                ) : null}
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-light"
                    onClick={() => onClosed("cardVisible", false)}
                >
                    Fermer
                </button>
            </ModalFooter>
        </Modal>
    );

});
PartnerCard.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    partner: PropTypes.object,
}
PartnerCard.defaultProps = {
    classes: "partners-card-modal",
    option: "",
    error: "",
    loading: false,
    partner: {}
}
export default PartnerCard