import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import redux, actions
import { connect } from 'react-redux'
import {
    filterOrders, listOrders, listUserOrders, resetOrders, setOrders, showOrder, transfertOrder
} from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME, ORDERS_ACCEPT, ORDERS_FINISH, ORDERS_LIST, ORDERS_SHOW, ORDERS_TRANSFERT
} from 'helpers/constants'

// import custom components
import OrderList from 'pages/orders/OrderList'
import OrderForward from 'pages/orders/OrderForward'
import OrderDetails from 'pages/orders/OrderDetails'
import OrderFinish from 'pages/orders/OrderFinish'


class TreatOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            detailsVisible: false,
            forwardVisible: false,
            finishVisible: false
        }
    }

    componentDidMount() {
        try {// Load orders
            this.onLoaded()
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * onLoaded.
     * 
     */
    onLoaded = () => {
        try {//Load current user's orders
            this.props.onListOrders(ORDERS_LIST)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onSearched.
     * 
     */
    onSearched = (searches) => {
        try {
            //Get props
            let { filter, onSetOrders, onFilterOrders } = this.props

            //Reset filters
            if (filter && filter.key) onFilterOrders(filter, true)

            //Search orders
            onSetOrders({ filtereds: searches })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onFiltered.
     * 
     */
    onFiltered = (selected) => {
        try {
            //Get props
            let { filter, onFilterOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Save filter
            onFilterOrders(selected, (selected.key === filter.key))
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onShowed.
     * 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onAccepted.
     * 
     */
    onAccepted = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_ACCEPT })

            //Show modal
            this.onToggled("forwardVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onTransferred.
     * 
     */
    onTransferred = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_TRANSFERT })

            //Show modal
            this.onToggled("forwardVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onFinished.
     * 
     */
    onFinished = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders, order } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_FINISH })

            //Show modal
            this.onToggled("finishVisible", true);
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { t, alls, orders, order, filter, loading, success, error, option } = this.props
        const { detailsVisible, forwardVisible, finishVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Mes courses")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Mes courses")}
                            subtitle={(alls.length > 0) ? (`(${alls.length})`) : ""}
                        />

                        <Row className="mb-2">
                            {((error != "") && (option === ORDERS_LIST)) ? (
                                <Col xl="12">
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                </Col>
                            ) : (loading && (option === ORDERS_LIST)) ? (
                                <Col xl="12">
                                    <Loader color="primary" />
                                </Col>
                            ) : (
                                <OrderList
                                    alls={alls}
                                    orders={orders}
                                    order={order}
                                    filter={filter}
                                    loading={[ORDERS_LIST].includes(option) ? loading : false}
                                    option={[ORDERS_LIST].includes(option) ? option : ""}
                                    error={[ORDERS_LIST].includes(option) ? error : ""}
                                    onShowed={this.onShowed}
                                    onSearched={this.onSearched}
                                    onFiltered={this.onFiltered}
                                    onAccepted={this.onAccepted}
                                    onRefreshed={this.onLoaded}
                                    showAccept showRefresh
                                />
                            )}
                        </Row>
                    </Container>
                </div>
                {(forwardVisible) ? (
                    <OrderForward
                        opened={forwardVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(finishVisible) ? (
                    <OrderFinish
                        opened={finishVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                        onTransferred={this.onTransferred}
                        onFinished={this.onFinished}
                        onRefreshed={this.onLoaded}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
TreatOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    alls: PropTypes.array,
    orders: PropTypes.array,
    order: PropTypes.object,
    filter: PropTypes.object,
    onListOrders: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onFilterOrders: PropTypes.func,
    onShowOrder: PropTypes.func,
    onTransfertOrder: PropTypes.func
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onListOrders: (option) => dispatch(listOrders("current_user", false, option)),
    onFilterOrders: (filter, reset) => dispatch(filterOrders(filter, reset)),
    onShowOrder: (id, option) => dispatch(showOrder(id, option)),
    onTransfertOrder: (data, option) => dispatch(transfertOrder(data, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(TreatOrders)))