import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import {
    Container, Row, Col, Alert, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import {
    addConfigPricing, addPricing, editPricing, listPricings,
    removePricing, resetPricings, setPricings, showConfigPricing
} from 'store/actions'

// import router
import { withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME, PRICINGS_ADD, PRICINGS_ADDCONFIGS, PRICINGS_AMOUNT, PRICINGS_EDIT,
    PRICINGS_LIST, PRICINGS_PERCENTAGE, PRICINGS_REMOVE, PRICINGS_SHOWCONFIGS
} from 'helpers/constants'

// import custom components
import BasePricings from './BasePricings'
import AdditionalPricings from './AdditionalPricings'

// import utilities
import { parseNumber } from 'helpers/utilities'

// Navs
const NAVS = [
    { key: "base-pricings", label: "Tarifs de base", icon: "bx-money" },
    { key: "additional-pricings", label: "Tarifs supplémentaires", icon: "bxs-offer" }
]


class Pricings extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            activeTab: "",
            formVisible: false
        }
    }

    componentDidMount() {
        try {// Load pricing configs
            this.onTabChanged(NAVS[0].key)
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetPricings } = this.props

                if (success) {
                    // Close modals
                    if ([PRICINGS_ADD, PRICINGS_EDIT, PRICINGS_REMOVE].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === PRICINGS_ADD) {
                        toast.success("Nouveau tarif supplémentaire ajoutée avec succès.")
                    } else if (option === PRICINGS_EDIT) {
                        toast.success("Tarif supplémentaire mis à jour avec succès.")
                    } else if (option === PRICINGS_REMOVE) {
                        toast.success("Tarif supplémentaire supprimé avec succès.")
                    }

                    //Reset global state
                    onResetPricings(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetPricings(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On change tab.
     * 
     * @param {*} currentTab 
     */
    onTabChanged = (currentTab) => {
        this.setState(prevState => ({
            activeTab: (prevState.activeTab !== currentTab) ? currentTab : prevState.activeTab
        }), () => {
            if (currentTab === "base-pricings") {
                this.props.onConfigPricings(PRICINGS_SHOWCONFIGS)
            } else if (currentTab === "additional-pricings") {
                this.props.onListPricings(PRICINGS_LIST)
            }
        })
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            //Get target
            let { value, name } = event.target

            //Get props
            let { pricing, onSetPricings } = this.props

            //Store values
            onSetPricings({
                pricing: { ...pricing, [`${name}Count`]: `${value.length}` }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * 
     * @param {*} value 
     * @param {*} option 
     */
    onPicked = (value, option) => {
        try {
            const { onSetPricings, configs } = this.props
            let formated_time = moment(`01/01/1970 ${value}:00`).unix()
            onSetPricings({ configs: { ...configs, [option]: formated_time } })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { pricing, onSetPricings } = this.props

            //Store values
            onSetPricings({
                pricing: { ...pricing, [name]: checked }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On checked.
     * 
     */
    onClicked = (option) => {
        try {
            //Get props
            let { pricing, onSetPricings } = this.props

            //Store values
            onSetPricings({
                pricing: { ...pricing, [option]: (!pricing[option]) }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddPricing, onEditPricing, onRemovePricing, option, pricing } = this.props

            switch (option) {
                case PRICINGS_ADD:
                    onAddPricing({
                        name: values.name.trim(),
                        value: parseNumber(values.value),
                        value_is_percentage: pricing.value_is_percentage,
                        enabled: pricing.enabled
                    }, option)
                    break;
                case PRICINGS_EDIT:
                    onEditPricing({
                        name: values.name.trim(),
                        value: parseNumber(values.value),
                        value_is_percentage: pricing.value_is_percentage,
                        enabled: pricing.enabled
                    }, pricing._id, option)
                    break;
                case PRICINGS_REMOVE:
                    onRemovePricing(pricing._id, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    onReseted = () => {
        try {
            const { configs, initial_configs, onSetPricings } = this.props

            if (!equal(configs, initial_configs)) {
                onSetPricings({ configs: { ...initial_configs } })
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onConfigured = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            const { onConfigurePricings, configs, initial_configs } = this.props

            onConfigurePricings({
                night_started_at: configs.night_started_at,
                night_ended_at: configs.night_ended_at,
                d_min_price: parseNumber(values.d_min_price),
                d_min_km_price: parseNumber(values.d_min_km_price),
                d_add_price_per_km: parseNumber(values.d_additional_price_per_km),
                n_min_price: parseNumber(values.n_min_price),
                n_min_km_price: parseNumber(values.n_min_km_price),
                n_add_price_per_km: parseNumber(values.n_additional_price_per_km)
            }, PRICINGS_ADDCONFIGS)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetPricings, onResetPricings } = this.props

            //Reset state
            onResetPricings(false)

            //Init state
            onSetPricings({
                pricing: { enabled: true, value_is_percentage: false, nameCount: "" },
                option: PRICINGS_ADD
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetPricings, onResetPricings } = this.props

            //Reset state
            onResetPricings(false)

            //Init state
            onSetPricings({
                pricing: { ...item, nameCount: `${item.name.length}` },
                option: PRICINGS_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onRemoving = (item) => {
        try {
            //Get props
            let { onSetPricings, onResetPricings } = this.props

            //Reset state
            onResetPricings(false)

            //Init state
            onSetPricings({
                pricing: { ...item },
                option: PRICINGS_REMOVE
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, pricings, pricing, configs, error, option, loading, success } = this.props
        const { activeTab, formVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Pricings")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Pricings")} />

                        <Row className="mb-2">
                            <Col xl="12">
                                <Card>
                                    <CardBody className="p-0">
                                        <Nav id="linear-nav" className="icon-tab nav-justified">
                                            {NAVS.map((nav, index) => (
                                                <NavItem key={`pricing_nav_tab_${index}`}>
                                                    <NavLink
                                                        className={(nav.key == activeTab) ? "active" : ""}
                                                        onClick={() => this.onTabChanged(nav.key)}
                                                    >
                                                        <span className="d-none d-sm-block">
                                                            <i className={`bx ${nav.icon} nav-icon mb-2`}></i>
                                                            <p className="fw-bold mb-0">{nav.label}</p>
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <i className={`bx ${nav.icon} nav-icon`}></i>
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>

                                        <TabContent activeTab={activeTab} className="p-3 text-muted">
                                            {NAVS.map((tab, index) => (activeTab == tab.key) && (
                                                <TabPane key={`employe_tab_${index}`} tabId={tab.key}>
                                                    <Row>
                                                        <Col sm="12">
                                                            {(tab.key === "base-pricings") ? (
                                                                <BasePricings
                                                                    pricings={configs}
                                                                    option={option}
                                                                    error={error}
                                                                    loading={loading}
                                                                    success={success}
                                                                    onSubmited={this.onConfigured}
                                                                    onChanged={this.onPicked}
                                                                />
                                                            ) : (tab.key === "additional-pricings") ? (
                                                                <AdditionalPricings
                                                                    pricings={pricings}
                                                                    pricing={pricing}
                                                                    option={option}
                                                                    error={error}
                                                                    loading={loading}
                                                                    formVisible={formVisible}
                                                                    onClosed={this.onToggled}
                                                                    onChanged={this.onChanged}
                                                                    onClicked={this.onClicked}
                                                                    onChecked={this.onChecked}
                                                                    onSubmited={this.onSubmited}
                                                                    onAdding={this.onAdding}
                                                                    onEditing={this.onEditing}
                                                                    onRemoving={this.onRemoving}
                                                                />
                                                            ) : (
                                                                <p className="m-0"> Section non prise en charge </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}
Pricings.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    pricings: PropTypes.array,
    pricing: PropTypes.object,
    configs: PropTypes.object,
    onListPricings: PropTypes.func,
    onSetPricings: PropTypes.func,
    onResetPricings: PropTypes.func,
    onAddPricing: PropTypes.func,
    onEditPricing: PropTypes.func,
    onConfigPricings: PropTypes.func,
    onConfigurePricings: PropTypes.func
}
const mapStateToProps = state => ({
    pricings: state.tarifications.pricings,
    pricing: state.tarifications.pricing,
    configs: state.tarifications.configs,
    initial_configs: state.tarifications.reset_configs,
    error: state.tarifications.error,
    option: state.tarifications.option,
    success: state.tarifications.success,
    loading: state.tarifications.loading
})
const mapDispatchToProps = dispatch => ({
    onListPricings: (option) => dispatch(listPricings(option)),
    onConfigPricings: (option) => dispatch(showConfigPricing(option)),
    onConfigurePricings: (data, option) => dispatch(addConfigPricing(data, option)),
    onSetPricings: (data) => dispatch(setPricings(data)),
    onResetPricings: (all) => dispatch(resetPricings(all)),
    onAddPricing: (data, option) => dispatch(addPricing(data, option)),
    onEditPricing: (data, id, option) => dispatch(editPricing(data, id, option)),
    onRemovePricing: (id, option) => dispatch(removePricing(id, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Pricings)))