import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, { CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM } from 'components/lists/CustomDatatable';

// import utilities
import { formatExportedFilename, formatPhoneNumberForDisplay } from 'helpers/utilities';


const CustomerTable = ({ items, start, end }) => {

    const columns = [
        {
            prop: "customer",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text fw-bold">
                        {row.customer.name}
                    </span>
                );
            }
        },
        {
            prop: "customer",
            title: "Contact",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <div>
                        <span className="d-block"> {row.customer.email} </span>
                        <span className="d-block">
                            {formatPhoneNumberForDisplay(row.customer.phone)}
                        </span>
                    </div>
                );
            }
        },
        {
            prop: "nature",
            title: "Nature/Coût",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <div>
                        <span className="d-block">
                            {row.nature}
                        </span>
                        <span className="d-block">
                            {row.payment.price}<small>(XOF)</small>
                        </span>
                    </div>
                );
            }
        },
        {
            prop: "start_point",
            title: "Point d’enlèvement",
            filterable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {row.start_point.address}
                    </span>
                );
            }
        },
        {
            prop: "delivery_point",
            title: "Point de livraison",
            filterable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {row.delivery_point.address}
                    </span>
                );
            }
        },
    ];

    const headers = [
        { label: "Nom", key: "customer.name" },
        { label: "Email", key: "customer.email" },
        { label: "Contact", key: "customer.phoneNumber" },
        { label: "Adresse", key: "customer.address" },
        { label: "Nature", key: "nature" },
        { label: "Coût(XOF)", key: "payment.price" },
        { label: "Point d’enlèvement", key: "start_point.address" },
        { label: "Point de livraison", key: "delivery_point.address" },
        { label: "Type", key: "customer.type" }
    ];

    const formatedData = items.map((item) => ({
        ...item,
        customer: {
            ...item.customer,
            phoneNumber: formatPhoneNumberForDisplay(item.customer.phone),
            type: (item.customer.partner ? "Contractuel" : "Occasionnel")
        }
    }))

    const formatedFilename = formatExportedFilename("clients", start, end)

    const onFilter = {
        customer: (value: any) => {
            return (value && value.name) ? value.name : ""
        },
        start_point: (value: any) => {
            return (value && value.address) ? value.address : ""
        },
        delivery_point: (value: any) => {
            return (value && value.address) ? value.address : ""
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        classes={CLASSES}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} clients` }}
                        leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                        exportOptions={{
                            exportAction: true,
                            label: "Exporter",
                            filename: `${formatedFilename}.csv`,
                            headers: headers,
                            data: formatedData
                        }}
                        onFilter={onFilter}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
CustomerTable.propTypes = {
    items: PropTypes.array,
    onExporting: PropTypes.func,
}
export default CustomerTable