// App Name
export const APP_NAME = "RapidPoste"


// Local Storage
export const USER_KEY = "USER KEY"
export const I18N_KEY = "I18N KEY"


// Request
export const STATUS_OK = 200
export const MESSAGE_OK = "ok"


// Characters
export const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"


// Filter All
export const FILTER_ALL = { key: "ALL", name: "Tous", color: "light" }


// Inputs
export const MIN_DESCRIPTION_INPUT = 50
export const MAX_DESCRIPTION_INPUT = 500
export const MAX_TEXT_INPUT = 255
export const MD_TEXT_INPUT = 100
export const MIN_TEXT_INPUT = 3


// Users Generate Password Message
export const PASSWORD_MESSAGE = "Cliquez pour générer un nouveau mot de passe. Assurez vous de copier et sauvegarder le mot de passe en lieu sûr avant de continuer."

// Users Roles
export const USER_ROOT = { key: "ROOT", name: "Root", color: "primary", tag: "" }
export const USER_AGENCY = { key: "AGENCY", name: "Agence", color: "blue", tag: "agencies" }
export const USER_FACTOR = { key: "FACTOR", name: "Facteur", color: "blue", tag: "factors" }
export const USER_BILLER = { key: "BILLER", name: "Facturier", color: "info", tag: "billers" }
export const USER_MASTER = { key: "MASTER", name: "Super Administrateur", color: "primary", tag: "masters" }
export const USER_SUPERVISOR = { key: "SUPERVISOR", name: "Superviseur", color: "indigo", tag: "supervisors" }
export const USER_AGENT = { key: "ASSIGNABLE", name: "Agents", color: "blue", tag: "agents" }

// Agents Options
export const AGENTS_LIST = "AGENTS_LIST"


// Agencies Options
export const AGENCIES_LIST = "AGENCIES_LIST"
export const AGENCIES_ADD = "AGENCIES_ADD"
export const AGENCIES_EDIT = "AGENCIES_EDIT"
export const AGENCIES_SHOW = "AGENCIES_SHOW"

// Agencies Types
export const AGENCIES_RELAY = { key: "RELAY", name: "Point Relais", label: "Agence partenaire de la Poste", color: "secondary" }
export const AGENCIES_CONTACT = { key: "CONTACT", name: "Point Contact", label: "Agence de la Poste", color: "primary" }


// Supervisors Options
export const SUPERVISORS_LIST = "SUPERVISORS_LIST"
export const SUPERVISORS_ADD = "SUPERVISORS_ADD"
export const SUPERVISORS_EDIT = "SUPERVISORS_EDIT"
export const SUPERVISORS_SHOW = "SUPERVISORS_SHOW"

// Masters Options
export const MASTERS_LIST = "MASTERS_LIST"
export const MASTERS_ADD = "MASTERS_ADD"
export const MASTERS_EDIT = "MASTERS_EDIT"
export const MASTERS_SHOW = "MASTERS_SHOW"


// Partners Options
export const PARTNERS_LIST = "PARTNERS_LIST"
export const PARTNERS_ADD = "PARTNERS_ADD"
export const PARTNERS_EDIT = "PARTNERS_EDIT"
export const PARTNERS_SHOW = "PARTNERS_SHOW"


// Billers Options
export const BILLERS_LIST = "BILLERS_LIST"
export const BILLERS_ADD = "BILLERS_ADD"
export const BILLERS_EDIT = "BILLERS_EDIT"
export const BILLERS_SHOW = "BILLERS_SHOW"


// Factors Options
export const FACTORS_LIST = "FACTORS_LIST"
export const FACTORS_ADD = "FACTORS_ADD"
export const FACTORS_EDIT = "FACTORS_EDIT"
export const FACTORS_SHOW = "FACTORS_SHOW"

// Factors Status
export const FACTORS_AVAILABLE = { key: "AVAILABLE", name: "Disponible", color: "success" }
export const FACTORS_UNAVAILABLE = { key: "UNAVAILABLE", name: "Indisponible", color: "danger" }
export const FACTORS_ONRUN = { key: "ON_RUN", name: "En course", color: "warning" }

// Factors Vehicles
export const VEHICLE_BIKE = { key: "MOTOR", name: "Moto", icon: "fas fa-motorcycle" }
export const VEHICLE_CAR = { key: "CAR", name: "Voiture", icon: "fas fa-car" }
export const VEHICLE_TRICYCLE = { key: "TRICYCLE", name: "Tricycle", icon: "fas fa-truck-pickup" }


// Orders Options
export const ORDERS_LIST = "ORDERS_LIST"
export const ORDERS_ADD = "ORDERS_ADD"
export const ORDERS_EDIT = "ORDERS_EDIT"
export const ORDERS_SHOW = "ORDERS_SHOW"
export const ORDERS_ASSIGN = "ORDERS_ASSIGN"
export const ORDERS_TRANSFERT = "ORDERS_TRANSFERT"
export const ORDERS_FINISH = "ORDERS_FINISH"
export const ORDERS_ACCEPT = "ORDERS_ACCEPT"
export const ORDERS_RESEARCH = "ORDERS_RESEARCH"
export const ORDERS_PAYBACK = "ORDERS_PAYBACK"
export const ORDERS_LOCATION = "ORDERS_LOCATION"
export const ORDERS_EVALUATE = "ORDERS_EVALUATE"

// Orders Status
export const ORDERS_PENDING = { key: "PENDING", name: "En attente", label: "En attentes", color: "pending", icon: "" }
export const ORDERS_ASSIGNED = { key: "ASSIGNED", name: "Prise en charge", label: "Prises en charge", color: "assigned", icon: "" }
export const ORDERS_REPORTED = { key: "REPORTED", name: "En cours", label: "En cours avec incident", color: "danger", icon: "bxs-error-alt text-danger" }
export const ORDERS_STARTED = { key: "STARTED", name: "En cours", label: "En cours", color: "ongoing", icon: "" }
export const ORDERS_COMPLETED = { key: "COMPLETED", name: "Terminée", label: "Terminées", color: "completed", icon: "" }
export const ORDERS_UNCOMPLETED = { key: "UNCOMPLETED", name: "", label: "", color: "", icon: "" }

// Orders Natures
export const NATURES_DOCUMENT = { key: "DOCUMENT", name: "Document", icon: "bxs-file-doc", color: "document", hex: "#556ee6" }
export const NATURES_ENCOMBRANT = { key: "ENCOMBRANT", name: "Encombrant", icon: "bxs-component", color: "bulky", hex: "#34c38f" }
export const NATURES_FRAIS = { key: "FRAIS", name: "Frais", icon: "bx-brightness", color: "fresh", hex: "#50a5f1" }
export const NATURES_FRAGILE = { key: "FRAGILE", name: "Fragile", icon: "bxs-wine", color: "fragile", hex: "#ffb74d" }

// Orders Per Page
export const ORDERS_PER_PAGE = 15

// Orders Events Types
export const EVENTS_TREATMENT = { key: "TREATMENT", name: "", label: "", color: "gray-light", icon: "right-arrow" }
export const EVENTS_INCIDENT = { key: "INCIDENT", name: "", label: "", color: "danger", icon: "error" }
export const EVENTS_RTS = { key: "RTS", name: "", label: "", color: "secondary", icon: "left-arrow" }
export const EVENTS_FORWARD = { key: "FORWARD", name: "", label: "", color: "warning", icon: "fast-forward" }

// Orders Location Picker Options
export const ORDERS_PICKUP = { key: "pickup_point", title: "Point d'enlèvement" }
export const ORDERS_DELIVERY = { key: "delivery_point", title: "Point de livraison" }

// Order Description Type
export const DESCRIPTION_AUDIO = "vocal"
export const DESCRIPTION_TEXT = "textual"

// Order Customer Type
export const CUSTOMER_CASUAL = "casual"
export const CUSTOMER_PARTNER = "contractual"


// Pricings Options
export const PRICINGS_LIST = "PRICINGS_LIST"
export const PRICINGS_ADD = "PRICINGS_ADD"
export const PRICINGS_EDIT = "PRICINGS_EDIT"
export const PRICINGS_REMOVE = "PRICINGS_REMOVE"
export const PRICINGS_ADDCONFIGS = "PRICINGS_ADDCONFIGS"
export const PRICINGS_SHOWCONFIGS = "PRICINGS_SHOWCONFIGS"

// Pricings Units
export const PRICINGS_PERCENTAGE = "%"
export const PRICINGS_AMOUNT = "XOF"

// Additional Pricing Message
export const PRICING_MESSAGE = "La valeur du tarif peut-être appliquée en pourcentage ou directement ajoutée au coût de la course. Cliquez sur le bouton pour choisir entre les deux options."


// Issues Options
export const ISSUES_LIST = "ISSUES_LIST"
export const ISSUES_ADD = "ISSUES_ADD"
export const ISSUES_EDIT = "ISSUES_EDIT"
export const ISSUES_SHOW = "ISSUES_SHOW"

// Issues Gravity
export const ISSUES_LOW = { key: "LOW", value: 2, name: "Faible", color: "secondary" }
export const ISSUES_MEDIUM = { key: "MEDIUM", values: 4, name: "Moyenne", color: "warning" }
export const ISSUES_HIGH = { key: "HIGH", values: 6, name: "Élevée", color: "danger" }


// Invoices Options
export const INVOICES_LIST = "INVOICES_LIST"
export const INVOICES_EDIT = "INVOICES_EDIT"
export const INVOICES_ADD = "INVOICES_ADD"
export const INVOICES_SHOW = "INVOICES_SHOW"
export const INVOICES_SEARCH = "INVOICES_SEARCH"
export const INVOICES_REGENERATE = "INVOICES_REGENERATE"
export const INVOICES_PAID_NOTIFY = "INVOICES_PAID_NOTIFY"
export const INVOICES_PAID_DETAILS = "INVOICES_PAID_DETAILS"

// Invoices Per Page
export const INVOICES_PER_PAGE = 15

// Invoices Status
export const INVOICES_PAID = { key: "PAID", name: "Payée", label: "Payées", color: "success", icon: "bxs-badge-check text-success" }
export const INVOICES_UNPAID = { key: "UNPAID", name: "Non payée", label: "Non payées", color: "muted", icon: "bxs-badge text-muted" }


// Statistics Options
export const STATISTICS_SHOW = "STATISTICS_SHOW"
export const STATISTICS_COMPLETEDS = "STATISTICS_COMPLETEDS"
export const STATISTICS_CUSTOMERS = "STATISTICS_CUSTOMERS"
export const STATISTICS_AGENCY = "STATISTICS_AGENCY"
export const STATISTICS_AGENTS = "STATISTICS_AGENTS"
export const STATISTICS_REFUNDEDS = "STATISTICS_REFUNDEDS"
export const STATISTICS_PRICES = "STATISTICS_PRICES"


// Logging Options
export const LOGGING_LIST = "LOGGING_LIST"