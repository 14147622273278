import {
	ORDERS_ERROR, ORDERS_SELECT, ORDERS_INSERT, ORDERS_RESET, ORDERS_SET, ORDERS_SUCCESS,
	ORDERS_UPDATE, ORDERS_ATTACH, ORDERS_GET, ORDERS_FILTER, ORDERS_FIND, ORDERS_AGENTS,
	ORDERS_RETRIEVE, ORDERS_REFUND, ORDERS_COMPLETE, ORDERS_FORWARD, ORDERS_CALCULATE
} from "./constants"

// import utilities
import { filterOrdersByStatus } from "helpers/utilities"

const INITIAL_STATE = {
	orders: [],
	filtereds: [],
	agents: [],
	order: {},
	filter: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const commandes = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case ORDERS_SELECT:
		case ORDERS_INSERT:
		case ORDERS_UPDATE:
		case ORDERS_ATTACH:
		case ORDERS_AGENTS:
		case ORDERS_FIND:
		case ORDERS_RETRIEVE:
		case ORDERS_GET:
		case ORDERS_REFUND:
		case ORDERS_COMPLETE:
		case ORDERS_FORWARD:
		case ORDERS_CALCULATE:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true,
				success: false
			}

		case ORDERS_SUCCESS:
			return {
				...state,
				orders: payload.data.orders ? payload.data.orders.reverse() : state.orders,
				filtereds: payload.data.orders ? payload.data.orders : state.filtereds,
				agents: payload.data.agents ? payload.data.agents.reverse() : state.agents,
				order: payload.data.order ? { ...payload.data.order, events: payload.data.order.events.reverse() } :
					payload.data.transfert ? { ...state.order, transfert: payload.data.transfert } :
						payload.data.payment ? { ...state.order, payment: payload.data.payment } :
							payload.data.cost ? { ...state.order, cost: payload.data.cost } : state.order,
				option: payload.option,
				loading: false,
				success: true
			}

		case ORDERS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case ORDERS_FILTER:
			return {
				...state,
				filter: (payload.reset) ? INITIAL_STATE.filter : payload.filter,
				filtereds: (payload.reset) ? state.orders : filterOrdersByStatus(state.orders, payload.filter.key)
			}

		case ORDERS_SET:
			return {
				...state,
				orders: (payload.orders) ? payload.orders : state.orders,
				filtereds: (payload.filtereds) ? payload.filtereds : state.filtereds,
				agents: (payload.agents) ? payload.agents : state.agents,
				order: (payload.order) ? payload.order : state.order,
				filter: (payload.filter) ? payload.filter : state.filter,
				error: (payload.error) ? payload.error : state.error,
				option: (payload.option) ? payload.option : state.option,
				loading: (payload.loading) ? payload.loading : state.loading,
				success: (payload.success) ? payload.success : state.success
			}

		case ORDERS_RESET:
			return {
				...state,
				orders: (payload.all) ? INITIAL_STATE.orders : state.orders,
				filtereds: (payload.all) ? INITIAL_STATE.filtereds : state.filtereds,
				agents: (payload.all) ? INITIAL_STATE.agents : state.agents,
				order: (payload.all) ? INITIAL_STATE.order : state.order,
				filter: (payload.all) ? INITIAL_STATE.filter : state.filter,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}

}

export default commandes