import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import map components
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'

// import components
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

// import axios
import axios from 'axios'

// import utilities
import { isSuccessfulRequest } from 'helpers/utilities'

// import erreurs
import { errorMessage } from 'helpers/erreurs'

// import location completer
import LocationCompleter from './LocationCompleter'

// some constants
const DEFAULT_CENTER = { lat: 9.307690, lng: 2.315834 }
const BASE_GEOREVERSE_LINK = "https://nominatim.openstreetmap.org/reverse?format=jsonv2&accept-language=fr"


const Locator = ({ opened, classes, size, geolocate, point, onPicked, onClosed }) => {

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: "fr"
    })

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [center, setCenter] = useState(DEFAULT_CENTER);
    const [map, setMap] = useState(/**@type google.maps.Map */(null));

    /**
     * Toggle Loading.
     * 
     * @param {*} load 
     * @param {*} error 
     */
    const toggleLoading = (load = true, error = "") => {
        setLoading(load)
        setError(error)
    }

    /**
     * Get geo-reverse value on current location
     * and continue.
     * 
     */
    const onSelected = () => {
        try {
            //Start loading
            toggleLoading()

            //Init config
            let config = {
                method: 'get',
                url: (`${BASE_GEOREVERSE_LINK}&lat=${center.lat}&lon=${center.lng}`),
                headers: {}
            }

            //Execute geo-reverse request
            axios(config).then((response) => {
                let { status, statusText, data } = response
                if (isSuccessfulRequest(status, statusText)) {
                    toggleLoading(false, "")
                    onPicked({
                        ...center,
                        address: (data.display_name ? data.display_name : "")
                    })
                    onClosed("locatorVisible", false)
                } else {
                    toggleLoading(false, errorMessage(response).message)
                    console.error(response)
                }
            }).catch((error) => {
                toggleLoading(false, errorMessage(response).message)
                console.error(error)
            })
        } catch (error) {
            toggleLoading(false, errorMessage(response).message)
            console.error(error)
        }
    }

    const onChanged = () => {
        try {
            let newCenter = map.getCenter().toJSON()
            setCenter(newCenter)
            map.panTo(newCenter)
        } catch (error) {
            console.error(error)
        }
    }

    const onClicked = (e) => {
        try {
            let newCenter = e.latLng.toJSON()
            setCenter(newCenter)
            map.panTo(newCenter)
        } catch (error) {
            console.error(error)
        }
    }

    const onCompleted = (item) => {
        let newCenter = { lat: Number(item.lat), lng: Number(item.lon) }
        setCenter(newCenter)
        map.panTo(newCenter)
        map.setZoom(15)
    }

    return (
        <React.Fragment>
            <Modal isOpen={opened} centered={true} className={classes} size={size}>
                <ModalHeader tag="h4">
                    {(point && point.label) ? point.label : "Choisir un emplacement"}
                    <small className="d-block text-muted fs-10">
                        Parcourir la carte pour sélectionner l'emplacement de votre choix.
                    </small>
                </ModalHeader>
                <ModalBody className="p-0 locator-body">
                    <div className="locator-content">
                        <div className="locator-map">
                            {(isLoaded) ? (
                                <GoogleMap
                                    center={center}
                                    zoom={6}
                                    mapContainerStyle={{ width: '100%', height: '500px' }}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        fullscreenControl: false,
                                    }}
                                    onLoad={(map) => setMap(map)}
                                    onDragEnd={() => onChanged()}
                                    onClick={(e) => onClicked(e)}
                                    clickableIcons={false}
                                >
                                    <Marker position={center} draggable={false} />
                                </GoogleMap>
                            ) : (loadError) ? (
                                <center>
                                    <p className="m-0 p-2 text-danger text-center">
                                        Oups! Impossible de charger la carte pour le moment!
                                    </p>
                                </center>
                            ) : (
                                <center>
                                    <p className="m-0 p-2 text-muted text-center">
                                        Chargement de la carte. Veuillez patienter...
                                    </p>
                                </center>
                            )}
                        </div>
                        {(isLoaded) ? (
                            <div className="locator-searcher">
                                <LocationCompleter onSelected={onCompleted} />
                            </div>
                        ) : null}
                    </div>
                    {loading ? (
                        <div className="locator-loader">
                            <span className="py-3 px-5 bg-light rounded fs-15">
                                Chargement de l'emplacement...
                            </span>
                        </div>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button" data-dismiss="modal" className="btn btn-light"
                        onClick={() => onClosed("locatorVisible", false)}
                        disabled={loading}
                    >
                        Fermer
                    </button>
                    <button
                        type="button" className="btn btn-primary save-point"
                        onClick={() => onSelected()}
                        disabled={loading}
                    >
                        Sélectionner
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )

}
Locator.propTypes = {
    opened: PropTypes.bool,
    size: PropTypes.string,
    classes: PropTypes.string,
    point: PropTypes.object,
    geolocate: PropTypes.bool,
    onClosed: PropTypes.func,
    onPicked: PropTypes.func
}
Locator.defaultProps = {
    opened: false,
    size: "lg",
    classes: "locator-modal",
    geolocate: false,
    point: {}
}
export default Locator