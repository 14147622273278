import React from 'react'
import PropTypes from 'prop-types'

// import components
import {
    Alert, Card, CardBody, CardHeader, CardTitle, Col, Row, Input
} from 'reactstrap'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import constants
import {
    ORDERS_ASSIGNED, ORDERS_PENDING, ORDERS_PER_PAGE, ORDERS_REPORTED,
    ORDERS_STARTED, USER_BILLER, USER_KEY, USER_SUPERVISOR
} from 'helpers/constants'

// import utilities
import {
    formatOrderNature, formatPhoneNumberForDisplay, getConstantObject, upperFirst
} from 'helpers/utilities'

// import custom common components
import Loader from 'components/common/Loader'
import Paginator from 'components/common/Paginator'
import Filter from 'components/common/Filter'



class OrderList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentOrders: [],
            currentPage: 0,
            totalPages: 0,
            query: "",
            userRole: ""
        }
    }

    componentDidMount() {
        try {
            if (localStorage.getItem(USER_KEY)) {
                this.setState({// extract user role
                    userRole: JSON.parse(localStorage.getItem(USER_KEY)).role
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onPageChanged.
     * 
     * @param {*} data 
     */
    onPageChanged = (data) => {
        // Get values
        const { orders, alls, totalPerPage } = this.props
        const { currentPage, totalPages } = data

        // Get offset, currentOrders
        const offset = (currentPage - 1) * totalPerPage
        const currentOrders = orders.slice(offset, (offset + totalPerPage))

        // Update state
        this.setState({ currentOrders, currentPage, totalPages })
    }

    /**
     * onChanged.
     * 
     * @param {*} e 
     */
    onChanged = (e) => {
        try {
            //Get input value
            let search = ((e && e.target) ? e.target.value.trim().toLowerCase() : "")

            //Get props valies
            let { alls } = this.props

            //Search orders
            let searchedOrders = alls
            if (search !== "") {
                searchedOrders = alls.filter((ox) => {
                    let { start_point, delivery_point, created_at, code, payment, nature } = ox
                    let ox_str = `${code} ${nature} ${start_point.contact} ${start_point.name}`
                    ox_str += ` ${delivery_point.contact} ${delivery_point.name} ${payment.price}`
                    ox_str += ` ${moment.unix(created_at).format("DD MMM YYYY à HH:mm")}`

                    return ox_str.toLowerCase().includes(search)
                })
            }

            //Update state and launch search
            this.setState({ query: search }, () => {
                this.props.onSearched(searchedOrders)
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * onFiltered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Reset search views
            if (this.state.query !== "") {
                this.setState({ query: "" }, () => {
                    document.getElementById("search-orders").value = ""
                })
            }

            //Launch filter
            this.props.onFiltered(selected)
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { alls, orders, filter, itemCol, totalPerPage, listClasses, onShowed, showFactors, onListed, showRefresh, onRefreshed, showAccept, onAccepted } = this.props
        const { currentOrders, currentPage, totalPages, query, userRole } = this.state

        return (
            <React.Fragment>
                <Col xl="12" sm="12" className="mt-2 mt-xl-0">
                    <Card className="shadow-none orders-header">
                        <CardHeader>
                            <Row className="align-items-center">
                                {(alls && (alls.length > 3) && (userRole === USER_SUPERVISOR.key)) ? (
                                    <Col xl="7" md="12">
                                        <Filter
                                            items={[ORDERS_PENDING, ORDERS_ASSIGNED, ORDERS_STARTED, ORDERS_REPORTED]}
                                            selected={filter}
                                            onFiltered={this.onFiltered}
                                        />

                                        {(filter && filter.key) ? (
                                            <small className="d-block text-muted fw-bolder">
                                                {(orders && (orders.length > 1)) ? (
                                                    <>
                                                        {`${orders.length} courses ${filter.label.toLowerCase()}`}
                                                    </>
                                                ) : (orders && (orders.length === 1)) ? (
                                                    <>
                                                        {`${orders.length} course ${filter.name.toLowerCase()}`}
                                                    </>
                                                ) : (orders && (orders.length === 0)) ? (
                                                    <>
                                                        {`Aucune course ${filter.name.toLowerCase()}`}
                                                    </>
                                                ) : null}
                                            </small>
                                        ) : null}
                                    </Col>
                                ) : null}
                                <Col xl={(alls && (alls.length > 3) && (userRole === USER_SUPERVISOR.key)) ? "5" : "12"} md="12" className="text-end">
                                    {(showFactors && onListed) ? (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm m-1 center-ibox"
                                            onClick={() => onListed()}
                                        >
                                            Voir les facteurs
                                            <i className="bx bx-right-arrow-alt text-muted ms-1" />
                                        </button>
                                    ) : null}
                                    {(showRefresh && onRefreshed) ? (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm m-1 text-muted center-ibox"
                                            onClick={() => onRefreshed()}
                                        >
                                            Actualiser
                                            <i className="bx bx-reset text-muted ms-1" />
                                        </button>
                                    ) : null}
                                    {(showAccept && onAccepted) ? (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm m-1 text-muted center-ibox"
                                            onClick={() => onAccepted()}
                                        >
                                            Accepter un transfert
                                            <i className="bx bx-transfer-alt text-muted ms-1" />
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>

                            <Row className="align-items-center mt-2">
                                <Col xl="3" md="3" className="p-2">
                                    <div className="search-box">
                                        <div className="position-relative">
                                            <Input
                                                id="search-orders"
                                                type="text"
                                                className="form-control"
                                                placeholder="Rechercher..."
                                                onChange={(e) => this.onChanged(e)}
                                                disabled={(alls && (alls.length === 0))}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                        </div>
                                    </div>

                                    {(query && (query !== "")) ? (
                                        <small className="d-block text-muted fw-bolder fs-10">
                                            {(orders && (orders.length > 1)) ? (
                                                <>
                                                    {`${orders.length} courses retrouvées`}
                                                </>
                                            ) : (orders && (orders.length === 1)) ? (
                                                <>
                                                    {`${orders.length} course retrouvée`}
                                                </>
                                            ) : (orders && (orders.length === 0)) ? (
                                                <>
                                                    {`Aucune course retrouvée`}
                                                </>
                                            ) : null}
                                        </small>
                                    ) : null}
                                </Col>
                                <Col xl="9" md="9" className="p-2 text-end">
                                    <Paginator
                                        pageNeighbours={1}
                                        items={orders}
                                        totalItems={orders.length}
                                        itemsPerPage={totalPerPage}
                                        paginationClasses="mb-0 justify-content-end"
                                        onPageChanged={this.onPageChanged}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    <Row className={listClasses}>
                        {(currentOrders && (currentOrders.length > 0) && currentOrders.map((item, index) => (
                            <OrderItem
                                key={`day_order_${index}`}
                                col={itemCol}
                                status={getConstantObject(item.current_status)}
                                onSelected={onShowed}
                                userRole={userRole}
                                {...item}
                            />
                        )))}
                        {((!currentOrders) || (currentOrders.length === 0)) ? (
                            <Col xl="12">
                                <Alert color="secondary"> Aucune course à afficher </Alert>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </React.Fragment>
        )
    }

}
OrderList.propTypes = {
    alls: PropTypes.array,
    orders: PropTypes.array,
    filter: PropTypes.object,
    showFactors: PropTypes.bool,
    showRefresh: PropTypes.bool,
    showAccept: PropTypes.bool,
    itemCol: PropTypes.number,
    totalPerPage: PropTypes.number,
    listClasses: PropTypes.string,
    onShowed: PropTypes.func,
    onFiltered: PropTypes.func
}
OrderList.defaultProps = {
    showFactors: false,
    showRefresh: false,
    showAccept: false,
    itemCol: 4,
    totalPerPage: ORDERS_PER_PAGE,
    listClasses: "",
    alls: [],
    orders: [],
    filter: {}
}
export default OrderList


const OrderItem = ({ col, _id, code, start_point, delivery_point, created_at, nature, payment, customer_id, status, reported, current_user_id, rts, delivery_auth, refund, userRole, onSelected }) => (
    <React.Fragment>
        <Col xl={`${col}`} md="6" sm="12">
            <Card>
                <CardBody className="p-0">
                    <div className="order-item">
                        <div className="order-header d-flex align-items-center justify-content-between border-bottom py-2 px-3">
                            <div>
                                <span
                                    className="d-block text-primary text-decoration-underline h-cursor"
                                    onClick={() => onSelected(_id)}
                                >
                                    Course <br className="d-none" /> #{code}
                                </span>
                                <span className="d-block fs-12" title="Date">
                                    <small>du</small> {" "}
                                    <span className="fw-bold">
                                        {moment.unix(created_at).format("DD MMM YYYY")}
                                    </span> {" "}
                                    <br className="d-none" />
                                    <small>à</small>{" "}
                                    <span className="fw-bold">
                                        {moment.unix(created_at).format("HH:mm")}
                                    </span>
                                </span>
                            </div>
                            <div>
                                <span className="d-block fs-13 fw-bolder" title="Coût">
                                    {payment.price}<small>(XOF)</small>
                                </span>
                                {(refund && refund.status) ? (
                                    <small className="d-block mt-1 fw-bold rounded p-1 bg-danger bg-soft text-danger">
                                        Remboursée
                                    </small>
                                ) : null}
                            </div>
                        </div>
                        <div className="order-body p-3">
                            <div className="d-flex orders-points">
                                <span className="points-box start-point me-1">
                                    <i className="bx bxs-map text-orange" />
                                </span>
                                <div className="flex-grow-1">
                                    <span className="d-block fs-12 text-muted fw-bolder">
                                        {formatPhoneNumberForDisplay(start_point.contact)}
                                    </span>
                                    <span className="d-block fs-13 fw-bolder ellipsis-text">
                                        {start_point.address}
                                    </span>
                                </div>
                            </div>
                            <div className="dash-separator mx-2" />
                            <div className="d-flex orders-points mt-3">
                                <span className="points-box end-point me-1">
                                    <i className="bx bxs-map text-blue" />
                                </span>
                                <div className="flex-grow-1">
                                    <span className="d-block fs-12 text-muted fw-bolder">
                                        {formatPhoneNumberForDisplay(delivery_point.contact)}
                                    </span>
                                    <span className="d-block fs-13 fw-bolder ellipsis-text">
                                        {delivery_point.address}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="order-footer d-flex align-items-center justify-content-between border-top py-2 px-3">
                            <span className="d-block fs-12 fw-bolder ellipsis-text" title="Nature">
                                {upperFirst(nature)}
                            </span>
                            <div className="d-flex align-items-center justify-content-center ms-1">
                                <span className={`badge bg-soft bg-${status.color} text-${status.color} fw-bold`}>
                                    {status.name}
                                </span>
                                {((status.key === ORDERS_STARTED.key) && reported) ? (
                                    <span className="ms-2" title="Incident signalé">
                                        <i className='bx bxs-error-alt bx-xs text-danger' />
                                    </span>
                                ) : null}
                                {(rts && rts.enabled) ? (
                                    <span className="ms-2 badge bg-soft bg-secondary text-secondary fw-bold">
                                        RTS
                                    </span>
                                ) : null}
                                {(delivery_auth && delivery_auth.enabled) ? (
                                    <span className="ms-1" title="Double confirmation">
                                        <i className='bx bx-check-double bx-xs text-blue' />
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </React.Fragment>
);