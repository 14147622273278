import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import {
    addAgency, addUser, editAgency, editUser, listUsers, resetUsers, setUsers
} from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom components
import AgencyTable from './AgencyTable'
import AgencyForm from './AgencyForm'
import AgencyCard from './AgencyCard'

// import utilities
import {
    formatPhoneNumberForDisplay, formatPhoneNumberForRequest, generateRandomCode
} from 'helpers/utilities'

// import constants
import {
    AGENCIES_ADD, AGENCIES_CONTACT, AGENCIES_EDIT, AGENCIES_LIST,
    AGENCIES_RELAY, AGENCIES_SHOW, APP_NAME, USER_AGENCY
} from 'helpers/constants'


class Agencies extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formVisible: false,
            cardVisible: false
        }
    }

    componentDidMount() {
        try {// Load agencies
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetAgencies } = this.props

                if (success) {
                    // Close modals
                    if ([AGENCIES_ADD, AGENCIES_EDIT].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === AGENCIES_ADD) {
                        toast.success("Nouvelle agence ajoutée avec succès.")
                    } else if (option === AGENCIES_EDIT) {
                        toast.success("Agence mise à jour avec succès.")
                    }

                    //Reset global state
                    onResetAgencies(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetAgencies(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { agency, onSetAgencies } = this.props

            //Store values
            onSetAgencies({
                user: { ...agency, [name]: checked }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On generated.
     * 
     */
    onGenerated = () => {
        try {
            //Get props
            let { agency, onSetAgencies } = this.props

            //Store values
            onSetAgencies({
                user: { ...agency, password: generateRandomCode(8, true) }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            //Get target
            let { value, name } = event.target

            //Get props
            let { agency, onSetAgencies } = this.props

            //Store values
            onSetAgencies({
                user: { ...agency, [`${name}Count`]: `${value.length}` }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            //Get props
            let { agency, onSetAgencies } = this.props

            //Get point boolean
            let point_value = (name === "selected_type") ? (selected && selected.key) ?
                (selected.key === AGENCIES_CONTACT.key) : false : agency.contact_point

            //Store values
            onSetAgencies({
                user: { ...agency, [name]: selected, contact_point: point_value }
            })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddAgency, onEditAgency, option, agency } = this.props

            switch (option) {
                case AGENCIES_ADD:
                    onAddAgency({
                        name: values.name.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        description: values.description.trim(),
                        contact_point: agency.contact_point,
                        enabled: agency.enabled
                    }, option)
                    break;
                case AGENCIES_EDIT:
                    onEditAgency({
                        name: values.name.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        description: values.description.trim(),
                        contact_point: agency.contact_point,
                        enabled: agency.enabled
                    }, agency._id, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetAgencies, onResetAgencies } = this.props

            //Reset state
            onResetAgencies(false)

            //Init state
            onSetAgencies({
                user: { enabled: true, contact_point: false, descriptionCount: "" },
                option: AGENCIES_ADD
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetAgencies, onResetAgencies } = this.props

            //Reset state
            onResetAgencies(false)

            //Init state
            onSetAgencies({
                user: {
                    ...item,
                    descriptionCount: `${item.description.length}`,
                    phone: formatPhoneNumberForDisplay(item.phone),
                    selected_type: item.contact_point ? AGENCIES_CONTACT : AGENCIES_RELAY
                },
                option: AGENCIES_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Show.
     * 
     * @param {*} item 
     */
    onShowing = (item) => {
        try {
            //Get props
            let { onSetAgencies, onResetAgencies } = this.props

            //Reset state
            onResetAgencies(false)

            //Init state
            onSetAgencies({ user: { ...item }, option: AGENCIES_SHOW })

            //Show modal
            this.onToggled("cardVisible", true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {// Load agencies
            let { agencies, onResetAgencies, onListAgencies } = this.props
            if (agencies.length > 0) onResetAgencies(true)
            onListAgencies(AGENCIES_LIST)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, agencies, agency, error, option, loading } = this.props
        const { formVisible, cardVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Agencies")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Agencies")}
                            subtitle={(agencies.length > 0) ? (`(${agencies.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === AGENCIES_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === AGENCIES_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <AgencyTable
                                        items={agencies}
                                        onAdding={this.onAdding}
                                        onEditing={this.onEditing}
                                        onShowing={this.onShowing}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(formVisible) ? (
                    <AgencyForm
                        opened={formVisible}
                        option={[AGENCIES_ADD, AGENCIES_EDIT].includes(option) ? option : ""}
                        error={[AGENCIES_ADD, AGENCIES_EDIT].includes(option) ? error : ""}
                        loading={[AGENCIES_ADD, AGENCIES_EDIT].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        onSubmited={this.onSubmited}
                        onChanged={this.onChanged}
                        onChecked={this.onChecked}
                        onGenerated={this.onGenerated}
                        onSelected={this.onSelected}
                        agency={agency}
                    />
                ) : null}
                {(cardVisible) ? (
                    <AgencyCard
                        opened={cardVisible}
                        option={[AGENCIES_SHOW].includes(option) ? option : ""}
                        error={[AGENCIES_SHOW].includes(option) ? error : ""}
                        loading={[AGENCIES_SHOW].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        agency={agency}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Agencies.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    agencies: PropTypes.array,
    agency: PropTypes.object,
    onListAgencies: PropTypes.func,
    onSetAgencies: PropTypes.func,
    onResetAgencies: PropTypes.func,
    onAddAgency: PropTypes.func,
    onEditAgency: PropTypes.func
}
const mapStateToProps = state => ({
    agencies: state.utilisateurs.agencies,
    agency: state.utilisateurs.user,
    error: state.utilisateurs.error,
    option: state.utilisateurs.option,
    success: state.utilisateurs.success,
    loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetAgencies: (data) => dispatch(setUsers(data)),
    onResetAgencies: (all) => dispatch(resetUsers(all)),
    onListAgencies: (option) => dispatch(listUsers(USER_AGENCY, "", false, false, option)),
    onAddAgency: (data, option) => dispatch(addUser(data, USER_AGENCY, option)),
    onEditAgency: (data, id, option) => dispatch(editUser(data, id, USER_AGENCY, true, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Agencies)))