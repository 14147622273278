import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import toaster
import { toast } from 'react-toastify'

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// import store and actions
import { connect } from 'react-redux'
import { paidInvoice, resetInvoices, setInvoices } from 'store/actions'

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"
import DateRanger from 'components/common/DateRanger'

// import constants
import { INVOICES_PAID_NOTIFY, MIN_TEXT_INPUT } from 'helpers/constants'


class PaidForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            paid_date: moment()
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success) {
                    if (option === INVOICES_PAID_NOTIFY) {
                        // Display toasts
                        toast.success("Facture mise à jour avec succès.")

                        //Close modal
                        onClosed("paidVisible", false)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetInvoices(false)
            this.props.onSetInvoices({ invoice: {} })
        } catch (error) {
            console.log(error)
        }
    }

    /**
    * On date ranged.
    * 
    * @param {*} start 
    * @param {*} end 
    */
    onDateRanged = (start, end) => {
        // Save values
        this.setState({ paid_date: start })
    }

    /**
     * On Submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            // Get props values
            let { option, invoice, period, onPaidInvoice } = this.props
            let { paid_date } = this.state

            // Get paid date
            // let paid_at = paid_date.unix()
            // let paid_at_reverse = moment.unix(paid_at).format("DD MM YYYY")
            // console.log({ paid_date, paid_at, paid_at_reverse })
            // let paid_at = values.payment_period ? values.payment_period : new Date()

            // Launch request
            if (option === INVOICES_PAID_NOTIFY) {
                onPaidInvoice({
                    bill_id: invoice._id,
                    payment_info: values.payment_observations.trim(),
                    payment_at: paid_date.unix()
                    // payment_at: moment(paid_at, "YYYY-MM-DD").unix()
                }, period.month, period.year, option)
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, success, onClosed, invoice } = this.props
        const { bill_reference, bill_price, created_at } = invoice
        const { paid_date } = this.state

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <ModalHeader tag="h4">
                    Notifier qu'une facture est payée
                    {(bill_reference && created_at) ? (
                        <span className="d-block fs-12 text-secondary">
                            Facture {" "}
                            <strong> #{bill_reference} </strong>
                            du {" "}
                            <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                        </span>
                    ) : null}
                </ModalHeader>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalBody>
                        {(invoice && invoice._id) ? (
                            <Row>
                                {(error && error !== "") ? (
                                    <Col xl="12">
                                        <Alert color="danger">{error}</Alert>
                                    </Col>
                                ) : null}

                                <Col xl="12" className="mb-3">
                                    <AvField
                                        name="payment_observations"
                                        label="Plus d'informations"
                                        type="textarea"
                                        rows="5"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Ce champs est obligatoire.",
                                            },
                                            minLength: {
                                                value: MIN_TEXT_INPUT,
                                                errorMessage: (`Ce champs a au moins ${MIN_TEXT_INPUT} caractères.`)
                                            }
                                        }}
                                    />
                                </Col>

                                <Col xl="12" className="mb-3">
                                    {/* <AvField
                                        name="payment_period"
                                        label="Date du paiement"
                                        type="date"
                                        validate={{
                                            max: {
                                                value: moment().format('YYYY-MM-DD'),
                                                errorMessage: "La date du paiement doit être inferieur à la date courante.",
                                            },
                                        }}
                                    /> */}
                                    <DateRanger
                                        start={paid_date}
                                        end={paid_date}
                                        onRanged={this.onDateRanged}
                                        singleDate
                                        maxSingleDate={moment()}
                                    // maxSingleDate={moment().format('MM/DD/YYYY')}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        {(loading) ? (
                            <Loader color="primary" />
                        ) : (
                            <>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-light"
                                    onClick={() => onClosed("paidVisible", false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary save-paid"
                                >
                                    Enregistrer
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

}
PaidForm.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func.isRequired,
    invoice: PropTypes.object,
}
PaidForm.defaultProps = {
    opened: false,
    classes: "invoice-paid-form-modal"
}
const mapStateToProps = state => ({
    invoice: state.factures.invoice,
    period: state.factures.period,
    option: state.factures.option,
    error: state.factures.error,
    success: state.factures.success,
    loading: state.factures.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onPaidInvoice: (data, month, year, option) => dispatch(paidInvoice(data, month, year, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(PaidForm)