import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import redux, actions
import { connect } from 'react-redux'
import { agencyStatistics, listUsers, resetOrders, resetStatistics, resetUsers, setOrders, setStatistics, setUsers } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'

// import constants
import { AGENCIES_LIST, APP_NAME, ORDERS_SHOW, STATISTICS_AGENCY, USER_AGENCY } from 'helpers/constants'
import Loader from 'components/common/Loader'
import Selecter from 'components/common/Selecter'
import AgencyOrdersTable from './AgencyOrdersTable'
import OrderDetails from 'pages/orders/OrderDetails'

const FAKE_ID = "62959e9c1747c368727ff162"


class AgencyOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selected_agency: null,
            detailsVisible: false,
        }
    }

    componentDidMount() {
        try {// Load agency orders
            this.onLoaded()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {

            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetStatistics(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected._id }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    onLoaded = () => {
        try {
            // Get props values
            let { onListStatistics, onListAgencies } = this.props

            // Load statistics
            // onListStatistics(FAKE_ID, STATISTICS_AGENCY)
            onListStatistics("", STATISTICS_AGENCY)

            // Load agencies
            onListAgencies(AGENCIES_LIST)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            // Load statistics
            let { onResetStatistics, onListStatistics } = this.props

            this.setState({ [name]: selected })

            onResetStatistics(false)

            // let agency_id = ((selected != null) ? FAKE_ID : "")
            let agency_id = ((selected != null) ? selected._id : "")

            onListStatistics(agency_id, STATISTICS_AGENCY)
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { t, statistics, option, loading, success, error, agencies, agencies_option, agencies_loading, agencies_success, agencies_error } = this.props
        const { selected_agency, detailsVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Les courses en agence")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Les courses en agence")}
                            subtitle={(statistics.length > 0) ? (`(${statistics.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === STATISTICS_AGENCY)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === STATISTICS_AGENCY)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <Row>
                                        <Col xl="9" sm="12" />
                                        <Col xl="3" sm="12">
                                            <Selecter
                                                selected={selected_agency || null}
                                                onSelected={this.onSelected}
                                                options={agencies}
                                                loading={[AGENCIES_LIST].includes(agencies_option) ? agencies_loading : false}
                                                id="selected_agency"
                                                name="selected_agency"
                                                placeholder="Sélectionner..."
                                            />
                                        </Col>

                                        <Col sm="12" className="my-2" />

                                        <Col xl="12">
                                            <AgencyOrdersTable
                                                items={statistics}
                                                agency={(selected_agency && selected_agency.name) ? selected_agency.name.replace((/ /gi),"_").toLowerCase() : ""}
                                                onShowed={this.onShowed}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
AgencyOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    statistics: PropTypes.array,
    onResetStatistics: PropTypes.func,
    onListStatistics: PropTypes.func,
    onSetStatistics: PropTypes.func
}
const mapStateToProps = state => ({
    statistics: state.statistiques.agency_statistics,
    error: state.statistiques.error,
    option: state.statistiques.option,
    success: state.statistiques.success,
    loading: state.statistiques.loading,
    agencies: state.utilisateurs.agencies,
    agencies_error: state.utilisateurs.error,
    agencies_option: state.utilisateurs.option,
    agencies_success: state.utilisateurs.success,
    agencies_loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetStatistics: (data) => dispatch(setStatistics(data)),
    onResetStatistics: (all) => dispatch(resetStatistics(all)),
    onListStatistics: (agency, option) => dispatch(agencyStatistics(agency, option)),
    onSetAgencies: (data) => dispatch(setUsers(data)),
    onResetAgencies: (all) => dispatch(resetUsers(all)),
    onListAgencies: (option) => dispatch(listUsers(USER_AGENCY, "", false, false, option)),
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(AgencyOrders)))