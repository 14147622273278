import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorInvoices, successInvoices } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import {
    INVOICES_GET, INVOICES_INSERT, INVOICES_FIND, INVOICES_SELECT, INVOICES_UPDATE, INVOICES_GENERATE, INVOICES_SET_PAID
} from "./constants"

// import errors
import { errorMessage, isUnauthorizedError } from "helpers/erreurs"

// import utilities
import { isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import {
    findInvoice, generateInvoice, getInvoice, selectInvoices, setPaidInvoice, updateInvoice
} from "services/invoiceService"

// import dummies
import { generateCustomers, generateInvoices, generateOrders } from "helpers/dummies"

// Invoices errors messages
const INVOICES_ERRORS = {
    "GENERATE": {
        403: "Opération non autorisée.",
        404: {
            currentMonth: "Vous ne pouvez pas générer de factures pour le mois en cours.",
            "404": "Aucune course retrouvée pour cette période. Génération impossible."
        },
        409: {
            generated: "Déjà Généré",
            "409": "Une facture ayant ces informations existe déjà."
        }
    }    
}

function* onSelectInvoices({ payload }) {
    try {
        const response = yield call(selectInvoices, payload.month, payload.year)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successInvoices({
                invoices: (response.data ? response.data : []),
                period: { year: payload.year, month: payload.month }
            }, payload.option))
        } else {
            console.error(response, "GET INVOICES ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "SELECT INVOICES ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onGetInvoice({ payload }) {
    try {
        console.log(payload, "GET INVOICE PAYLOAD")
    } catch (error) {
        console.error(error, "GET INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onGenerateInvoices({ payload }) {
    try {
        const response = yield call(generateInvoice, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectInvoices, payload.data.month, payload.data.year)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successInvoices({
                    invoices: (list.data ? list.data : []),
                    period: { year: payload.data.year, month: payload.data.month }
                }, payload.option))
            } else {
                console.error(list, "GENERATE INVOICES ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            console.error(response, "GENERATE INVOICES ERROR")
            let { unauth, message } = errorMessage(response, INVOICES_ERRORS.GENERATE)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "GENERATE INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onPaidInvoice({ payload }) {
    try {
        const response = yield call(setPaidInvoice, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectInvoices, payload.month, payload.year)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successInvoices({
                    invoices: (list.data ? list.data : [])
                }, payload.option))
            } else {
                console.error(list, "UPDATE INVOICE ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            console.error(response, "UPDATE INVOICE ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.log(error, "UPDATE INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onUpdateInvoice({ payload }) {
    try {
        console.log(payload, "UPDATE INVOICE PAYLOAD")
    } catch (error) {
        console.log(error, "UPDATE INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onInsertInvoice({ payload }) {
    try {
        console.log(payload, "INSERT INVOICE PAYLOAD")
    } catch (error) {
        console.log(error, "INSERT INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onFindInvoice({ payload }) {
    try {
        const response = yield call(findInvoice, payload.reference, payload.month, payload.year)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successInvoices({
                invoices: (response.data ? response.data : [])
            }, payload.option))
        } else {
            console.error(response, "FIND INVOICE ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "FIND INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}


function* InvoiceSaga() {
    yield takeEvery(INVOICES_SELECT, onSelectInvoices)
    yield takeEvery(INVOICES_GET, onGetInvoice)
    yield takeEvery(INVOICES_FIND, onFindInvoice)
    yield takeEvery(INVOICES_INSERT, onInsertInvoice)
    yield takeEvery(INVOICES_UPDATE, onUpdateInvoice)
    yield takeEvery(INVOICES_GENERATE, onGenerateInvoices)
    yield takeEvery(INVOICES_SET_PAID, onPaidInvoice)
}

export default InvoiceSaga