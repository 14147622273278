import React from "react"
import PropTypes from 'prop-types'

// import components
import { Row, Col, Alert, Input, Label, Card, CardBody, CardTitle, CardText } from "reactstrap"

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import constants
import { } from "helpers/constants"

// import common custom components
import DateRanger from "components/common/DateRanger"


const SearchoForm = React.memo(({ query, onSearched, onChanged, onRanged, onSubmited }) => {

    return (
        <React.Fragment>
            {/* <Col xl="3" sm="12" className="pe-3 pe-xxl-4"> */}
                <Card className="shadow-none">
                    <CardBody>
                        <Col sm="12" className="mb-5">
                            <Row>
                                <Col sm={12} className="mb-2">
                                    <label htmlFor="code" className="form-label">
                                        Code
                                    </label>
                                    <input
                                        id="code" name="code" type="text"
                                        className="form-control"
                                        onChange={(e) => onChanged(e)}
                                    />
                                </Col>
                                <Col sm={12}>
                                    <div className="d-flex align-items-end justify-content-end">
                                        <button
                                            type="submit" className="btn btn-primary px-2"
                                            onClick={() => onSearched()}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <i className="bx bx-search-alt bx-xs text-white me-1" />{" "}
                                                Rechercher
                                            </div>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <AvForm onValidSubmit={onSubmited}>
                                <Row>
                                    <Col sm={12} className="mb-3">
                                        <AvField
                                            type="text"
                                            name="phone"
                                            label="Contact du client"
                                            mask="(+22\9) 99 99 99 99"
                                            maskChar="-"
                                            tag={[Input, InputMask]}
                                            validate={{
                                                minLength: {
                                                    value: 8,
                                                    errorMessage: 'Le numéro de téléphone a au moins 8 caractères'
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Le numéro de téléphone est obligatoire.',
                                                },
                                            }}
                                        />
                                    </Col>
                                    {(query.withPeriod) ? (
                                        <Col sm={12} className="mb-3">
                                            <label htmlFor="period" className="form-label">
                                                Date/Période de la recherche
                                            </label>
                                            <div className="form-group">
                                                <DateRanger
                                                    start={query.start}
                                                    end={query.end}
                                                    onRanged={onRanged}
                                                />
                                            </div>
                                        </Col>
                                    ) : null}
                                    <Col sm={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input" name="withPeriod"
                                                    id="forPeriod" type="checkbox"
                                                    onChange={(e) => onChanged(e)}
                                                />
                                                <label
                                                    className={`form-check-label ${query.withPeriod ? "fw-bolder" : "fw-light"}`}
                                                    htmlFor="forPeriod"
                                                >
                                                    Définir une date/période
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} className="mb-3">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input" name="partner"
                                                    id="forPartenaire" type="checkbox"
                                                    onChange={(e) => onChanged(e)}
                                                />
                                                <label
                                                    className={`form-check-label ${query.partner ? "fw-bolder" : "fw-light"}`}
                                                    htmlFor="forPartenaire"
                                                >
                                                    Client contractuel
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="d-flex align-items-end justify-content-end">
                                            <button type="submit" className="btn btn-primary px-2">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <i className="bx bx-search-alt bx-xs text-white me-1" /> {" "}
                                                    Rechercher
                                                </div>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </CardBody>
                </Card>
            {/* </Col> */}
        </React.Fragment>
    );

});
SearchoForm.propTypes = {
    query: PropTypes.object,
    onRanged: PropTypes.func,
    onSubmited: PropTypes.func,
    onChanged: PropTypes.func,
    onSearched: PropTypes.func
}
SearchoForm.defaultProps = {
    query: {}
}
export default SearchoForm