import { ORDERS_ERROR, ORDERS_SELECT, ORDERS_INSERT, ORDERS_RESET, ORDERS_SET, ORDERS_SUCCESS, ORDERS_UPDATE, ORDERS_ATTACH, ORDERS_GET, ORDERS_FILTER, ORDERS_FIND, ORDERS_AGENTS, ORDERS_RETRIEVE, ORDERS_REFUND, ORDERS_COMPLETE, ORDERS_FORWARD, ORDERS_CALCULATE } from "./constants"


export const listOrders = (filter, for_user, option) => ({
    type: ORDERS_SELECT,
    payload: { filter, for_user, option },
})

export const listAgents = (filter, enabled_only, option) => ({
    type: ORDERS_AGENTS,
    payload: { filter, enabled_only, option },
})

export const showOrder = (id, option) => ({
    type: ORDERS_GET,
    payload: { id, option },
})

export const addOrder = (data, option) => ({
    type: ORDERS_INSERT,
    payload: { data, option },
})

export const editOrder = (data, id, option) => ({
    type: ORDERS_UPDATE,
    payload: { data, id, option },
})

export const finishOrder = (data, id, option) => ({
    type: ORDERS_COMPLETE,
    payload: { data, id, option },
})

export const assignOrder = (data, id, type, option) => ({
    type: ORDERS_ATTACH,
    payload: { data, id, type, option },
})

export const transfertOrder = (data, accept, option) => ({
    type: ORDERS_FORWARD,
    payload: { data, accept, option },
})

export const repayOrder = (data, option) => ({
    type: ORDERS_REFUND,
    payload: { ...data, option },
})

export const evaluateOrder = (data, option) => ({
    type: ORDERS_CALCULATE,
    payload: { data, option },
})

export const searchOrders = (data, option) => ({
    type: (data.customer_phone) ? ORDERS_FIND : ORDERS_RETRIEVE,
    payload: { data, option },
})

export const filterOrders = (filter, reset = false) => ({
    type: ORDERS_FILTER,
    payload: { filter, reset },
})

export const successOrders = (data, option) => ({
    type: ORDERS_SUCCESS,
    payload: { data, option },
})

export const errorOrders = (error = "", option = "") => ({
    type: ORDERS_ERROR,
    payload: { error, option },
})

export const setOrders = (data, replace = true) => ({
    type: ORDERS_SET,
    payload: { ...data, replace: replace },
})

export const resetOrders = (all) => ({
    type: ORDERS_RESET,
    payload: { all },
})