import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import {
    filterInvoices, listInvoices, regenerateInvoice,
    resetInvoices, setInvoices
} from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME, INVOICES_EDIT, INVOICES_LIST, INVOICES_PAID_NOTIFY,
    INVOICES_REGENERATE, INVOICES_SHOW, PARTNERS_SHOW
} from 'helpers/constants'

// import custom components
import InvoiceList from './InvoiceList'
import InvoiceDetails from './InvoiceDetails'
import GenerateForm from './GenerateForm'
import PaidForm from './PaidForm'

// import custom common components
import DateRanger from 'components/common/DateRanger'
import MonthPicker from 'components/common/MonthPicker'


class Invoices extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            detailsVisible: false,
            generateVisible: false,
            paidVisible: false
        }
    }

    componentDidMount() {
        try {// Load invoices
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                //
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetInvoices(true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On loaded.
     * Load invoices list.
     * 
     */
    onLoaded = () => {
        try {
            let { onListInvoices, period } = this.props
            let year = (period.year) ? period.year : moment().subtract(1, 'month').startOf('month').year()
            let month = (period.month) ? period.month : (moment().subtract(1, 'month').startOf('month').month() + 1)
            onListInvoices(month, year, INVOICES_LIST)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On searched.
     *  
     * @param {*} list 
     * @returns 
     */
    onSearched = (list) => {
        try {
            //Get props
            let { filter, onSetInvoices, onFilterInvoices } = this.props

            //Reset filters
            if (filter && filter.key) onFilterInvoices(filter, true)

            //Search orders
            onSetInvoices({ filtereds: list })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On filtered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Get props
            let { filter, onFilterInvoices, onResetInvoices } = this.props

            //Reset state
            onResetInvoices(false)

            //Save filter
            onFilterInvoices(selected, (selected.key === this.props.filter.key))
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Regenerated.
     * 
     */
    onRegenerated = () => {
        try {
            //Get props
            let { onResetInvoices, onSetInvoices } = this.props

            //Reset state
            onResetInvoices(false)

            //Init state
            onSetInvoices({ option: INVOICES_REGENERATE })

            //Show modal
            this.onToggled("generateVisible", true);
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Showed.
     * 
     * @param {*} id 
     * @param {*} option 
     */
    onShowed = (id, option) => {
        try {
            // Get props values
            let { invoices, onResetInvoices, onSetInvoices } = this.props

            // Reset state
            onResetInvoices(false)

            // Get selected invoice item
            let selected = invoices.filter((item) => (item._id === id))

            // Set selected invoice item
            onSetInvoices({ invoice: { ...selected[0] }, option: option })

            //Show modal
            this.onToggled(((option === INVOICES_PAID_NOTIFY) ? "paidVisible" : "detailsVisible"), true)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * On Month Picked.
     * 
     * @param {*} year 
     * @param {*} month 
     * @param {*} option 
     */
    onMonthPicked = (year, month, option = "period") => {
        try {
            let { onListInvoices, period } = this.props
            if ((period.year !== year) || (period.month !== month)) {
                onListInvoices(month, year, INVOICES_LIST)
            }
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const {
            t, alls, invoices, invoice, filter, period, error, option, loading,
            customer, customer_loading, customer_option, customer_error, customer_success
        } = this.props
        const { detailsVisible, paidVisible, generateVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Invoices")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Invoices")}
                            subtitle={(invoices.length > 0) ? (`(${invoices.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === INVOICES_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error} {" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === INVOICES_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <Row>
                                        {(period && period.year && period.month) ? (
                                            <>
                                                <Col xl="3" sm="12">
                                                    <MonthPicker
                                                        name="period"
                                                        selected={period}
                                                        onPicked={this.onMonthPicked}
                                                    />
                                                </Col>
                                                <Col sm="12" className="my-2" />
                                            </>
                                        ) : null}

                                        <InvoiceList
                                            items={invoices}
                                            alls={alls}
                                            filter={filter}
                                            onShowed={this.onShowed}
                                            onSearched={this.onSearched}
                                            onFiltered={this.onFiltered}
                                            onRegenerated={this.onRegenerated}
                                        />
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(detailsVisible) ? (
                    <InvoiceDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(generateVisible) ? (
                    <GenerateForm
                        opened={generateVisible}
                        onClosed={this.onToggled}
                        currentPeriod={period}
                    />
                ) : null}
                {(paidVisible) ? (
                    <PaidForm
                        opened={paidVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Invoices.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    invoices: PropTypes.array,
    invoice: PropTypes.object,
    onListInvoices: PropTypes.func,
    onSetInvoices: PropTypes.func,
    onResetInvoices: PropTypes.func,
}
const mapStateToProps = state => ({
    alls: state.factures.invoices,
    invoices: state.factures.filtereds,
    invoice: state.factures.invoice,
    filter: state.factures.filter,
    period: state.factures.period,
    error: state.factures.error,
    option: state.factures.option,
    success: state.factures.success,
    loading: state.factures.loading,
    customer: state.clients.customer,
    customer_loading: state.clients.loading,
    customer_error: state.clients.error,
    customer_option: state.clients.option,
    customer_success: state.clients.success,
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onListInvoices: (month, year, option) => dispatch(listInvoices(month, year, option)),
    onFilterInvoices: (filter, reset) => dispatch(filterInvoices(filter, reset)),
    onRegenerateInvoices: (data, option) => dispatch(regenerateInvoice(data, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Invoices)))