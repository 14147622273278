import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import deep-equal
import equal from "deep-equal"

// import redux, actions
import { connect } from 'react-redux'
import {
    listLogging, listUsers, resetLogging, resetUsers, setLogging, setUsers
} from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'
import Selecter from 'components/common/Selecter'
import DateRanger from 'components/common/DateRanger'

// import custom components
import LoggingTable from './LoggingTable'

// import constants
import {
    APP_NAME, LOGGING_LIST, MASTERS_LIST, USER_KEY, USER_MASTER, USER_ROOT
} from 'helpers/constants'

// import utilities
import { convertDatesOnTimestamps } from 'helpers/utilities'


class Logging extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            start: moment(),
            end: moment(),
            selected_user: null,
            role: "",
            is_root: false
        }
    }

    componentDidMount() {
        try {
            // Set with root value
            let { role } = JSON.parse(localStorage.getItem(USER_KEY))
            this.setState({
                is_root: (role && (role === USER_ROOT.key))
            }, () => {
                let { onListMasters } = this.props
                onListMasters(MASTERS_LIST)
            })
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { mr_success, mr_loading } = this.props
                if ((mr_success) && (!mr_loading)) {
                    if ((!prevProps.mr_success) && prevProps.mr_loading) {
                        this.onLoaded()
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetMasters(true)
            this.props.onResetLogging(true)
        } catch (error) {
            console.error(error)
        }
    }

    onLoaded = () => {
        try {
            // Get props values
            let { onListLogging, masters } = this.props

            // Get state values
            let { start, end, selected_user, is_root } = this.state

            // Get converted dates
            let timestamps = convertDatesOnTimestamps(start, end)

            //Get state values
            let user_id = ((selected_user && selected_user._id) ? selected_user._id : "")

            // Load loggings
            onListLogging(timestamps.start, timestamps.end, user_id, is_root, masters, LOGGING_LIST)
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * On date ranged.
    * 
    * @param {*} start 
    * @param {*} end 
    */
    onDateRanged = (start, end) => {
        // Save values
        this.setState({ start: start, end: end })

        // Get props values
        let { onListLogging, onResetLogging, masters } = this.props

        // Reset loggings current values
        onResetLogging(true)

        //Get state values
        let { selected_user, is_root } = this.state
        let user_id = ((selected_user && selected_user._id) ? selected_user._id : "")

        // Get converted dates
        let timestamps = convertDatesOnTimestamps(start, end)

        // Load loggings with new values
        onListLogging(timestamps.start, timestamps.end, user_id, is_root, masters, LOGGING_LIST)
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            // Get props
            let { onResetLogging, onListLogging, masters } = this.props

            // Get state values
            let { start, end, is_root } = this.state

            // Reset loggings current values
            onResetLogging(true)

            // Save values
            this.setState({ [name]: selected })
            let user_id = ((selected && selected._id) ? selected._id : "")

            // Get converted dates
            let timestamps = convertDatesOnTimestamps(start, end)

            // Load loggings with new values
            onListLogging(timestamps.start, timestamps.end, user_id, is_root, masters, LOGGING_LIST)
        } catch (error) {
            console.error(error)
        }
    }


    render() {
        const { t, loggings, option, loading, success, error, masters, mr_loading, mr_option } = this.props
        const { start, end, selected_user } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Les logs")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Les logs")}
                            subtitle={(loggings.length > 0) ? (`(${loggings.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === LOGGING_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : ((loading && (option === LOGGING_LIST)) || (mr_loading && (mr_option === MASTERS_LIST))) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <Row>
                                        <Col xl="6" sm="12" />

                                        <Col xl="3" sm="12">
                                            <Selecter
                                                selected={selected_user || null}
                                                onSelected={this.onSelected}
                                                options={masters}
                                                loading={(mr_loading && (mr_option === MASTERS_LIST))}
                                                valueAttribut="_id"
                                                labelAttribut="name"
                                                id="selected_user"
                                                name="selected_user"
                                                placeholder="Sélectionner..."
                                            />
                                        </Col>

                                        <Col xl="3" sm="12">
                                            <DateRanger
                                                start={start}
                                                end={end}
                                                onRanged={this.onDateRanged}
                                            />
                                        </Col>

                                        <Col sm="12" className="my-2" />

                                        <Col xl="12">
                                            <LoggingTable
                                                items={loggings}
                                                masters={masters}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}
Logging.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    loggings: PropTypes.array,
    onResetLogging: PropTypes.func,
    onListLogging: PropTypes.func,
    onSetLogging: PropTypes.func
}
const mapStateToProps = state => ({
    loggings: state.journalisation.loggings,
    error: state.journalisation.error,
    option: state.journalisation.option,
    success: state.journalisation.success,
    loading: state.journalisation.loading,
    masters: state.utilisateurs.masters,
    mr_error: state.utilisateurs.error,
    mr_option: state.utilisateurs.option,
    mr_success: state.utilisateurs.success,
    mr_loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetMasters: (data) => dispatch(setUsers(data)),
    onResetMasters: (all) => dispatch(resetUsers(all)),
    onListMasters: (option) => dispatch(listUsers(USER_MASTER, "", false, false, option)),
    onSetLogging: (data) => dispatch(setLogging(data)),
    onResetLogging: (all) => dispatch(resetLogging(all)),
    onListLogging: (start, end, user, is_root, list, option) => dispatch(listLogging(start, end, user, is_root, list, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Logging)))