import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import bootstrap components
import { Container, Row, Col, Alert, Card, CardBody, CardTitle } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import {
    completedStatistics, listAgents, listStatistics, resetOrders, resetStatistics, setOrders, showStatistics
} from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'
import DateRanger from 'components/common/DateRanger'
import Selecter from 'components/common/Selecter'

// import custom components
import RevenuesCard from './RevenuesCard'
import TotalsCard from './TotalsCard'
import NaturesCard from './NaturesCard'
import DurationTable from './DurationTable'

// import custom orders components
import OrderDetails from 'pages/orders/OrderDetails'

// import constants
import {
    APP_NAME, AGENTS_LIST, NATURES, NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS,
    ORDERS_COMPLETED, ORDERS_LIST, ORDERS_SHOW, STATISTICS_SHOW, USER_AGENCY, USER_AGENT, USER_BILLER,
    USER_FACTOR, USER_KEY, USER_MASTER, USER_ROOT, USER_SUPERVISOR
} from 'helpers/constants'


class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            start: moment(),
            end: moment(),
            selected_agent: null,
            durationVisible: false,
            detailsVisible: false,
            userRole: ""
        }
    }

    componentDidMount() {
        try {
            // extract user role
            let userRole = (
                (localStorage.getItem(USER_KEY)) ? JSON.parse(localStorage.getItem(USER_KEY)).role : ""
            )

            //Save user role and continue
            this.setState({ userRole }, () => {
                //Load statistics
                this.onLoaded()
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "durationVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    onTimestampFormated(start, end) {
        return {
            start: moment(`${start.format('DD/MM/YYYY')} 00:00:00`, "DD/MM/YYYY HH:mm:ss").unix(),
            end: moment(`${end.format('DD/MM/YYYY')} 23:59:59`, "DD/MM/YYYY HH:mm:ss").unix()
        }
    }

    onLoaded = () => {
        try {
            //Get user role 
            let { userRole } = this.state

            //Load statistics
            if ([USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key, USER_BILLER.key].includes(userRole)) {
                //Get props values
                let { onShowStatistics, onListAgents } = this.props

                //Get state values
                let { start, end } = this.state

                //Get timestamps from dates values
                let timestamps = this.onTimestampFormated(start, end)

                // Load statistics with values
                onShowStatistics(timestamps.start, timestamps.end, "", STATISTICS_SHOW)

                // Load statistics with new values
                if (userRole !== USER_BILLER.key) onListAgents(AGENTS_LIST)
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onDateRanged = (start, end) => {
        //Get selected agent
        let { selected_agent } = this.state
        let agent_id = (selected_agent !== null) ? selected_agent._id : ""

        //Save values
        this.setState({ start: start, end: end })

        //Get props values
        let { onShowStatistics, onResetStatistics } = this.props

        //Reset current values
        onResetStatistics(false)

        //Get timestamps from dates values
        let timestamps = this.onTimestampFormated(start, end)

        // Load statistics with values
        onShowStatistics(timestamps.start, timestamps.end, agent_id, STATISTICS_SHOW)
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            // Get props values
            let { onShowStatistics, onResetStatistics, onListStatistics } = this.props

            //Save values
            this.setState({ [name]: selected })

            //Get agent id
            let agent_id = (selected !== null) ? selected._id : ""

            // Get state values
            let { start, end } = this.state

            //Get timestamps from dates values
            let timestamps = this.onTimestampFormated(start, end)

            // Load statistics with values
            onShowStatistics(timestamps.start, timestamps.end, agent_id, STATISTICS_SHOW)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On submited.
     * 
     * @param {*} event 
     */
    onOrdersDurationsListed = () => {
        try {
            //Get props values
            let { onResetStatistics, onListCompletedOrders } = this.props

            //Get state values
            let { start, end } = this.state
            let timestamps = this.onTimestampFormated(start, end)

            //Reset state
            onResetStatistics(false)

            //Init state
            onListCompletedOrders(timestamps.start, timestamps.end, ORDERS_LIST)

            //Show modal
            this.onToggled("durationVisible", true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Formated.
     * 
     * @returns 
     */
    onFormated() {
        let { requested, completed, uncompleted } = this.props.statistics

        let formated_list = [
            {
                ...NATURES_DOCUMENT,
                total: requested.per_nature[NATURES_DOCUMENT.key] ? requested.per_nature[NATURES_DOCUMENT.key] : 0,
                completed: completed.per_nature[NATURES_DOCUMENT.key] ? completed.per_nature[NATURES_DOCUMENT.key] : 0,
                uncompleted: uncompleted.per_nature[NATURES_DOCUMENT.key] ? uncompleted.per_nature[NATURES_DOCUMENT.key] : 0
            },
            {
                ...NATURES_ENCOMBRANT,
                total: requested.per_nature[NATURES_ENCOMBRANT.key] ? requested.per_nature[NATURES_ENCOMBRANT.key] : 0,
                completed: completed.per_nature[NATURES_ENCOMBRANT.key] ? completed.per_nature[NATURES_ENCOMBRANT.key] : 0,
                uncompleted: uncompleted.per_nature[NATURES_ENCOMBRANT.key] ? uncompleted.per_nature[NATURES_ENCOMBRANT.key] : 0
            },
            {
                ...NATURES_FRAGILE,
                total: requested.per_nature[NATURES_FRAGILE.key] ? requested.per_nature[NATURES_FRAGILE.key] : 0,
                completed: completed.per_nature[NATURES_FRAGILE.key] ? completed.per_nature[NATURES_FRAGILE.key] : 0,
                uncompleted: uncompleted.per_nature[NATURES_FRAGILE.key] ? uncompleted.per_nature[NATURES_FRAGILE.key] : 0
            },
            {
                ...NATURES_FRAIS,
                total: requested.per_nature[NATURES_FRAIS.key] ? requested.per_nature[NATURES_FRAIS.key] : 0,
                completed: completed.per_nature[NATURES_FRAIS.key] ? completed.per_nature[NATURES_FRAIS.key] : 0,
                uncompleted: uncompleted.per_nature[NATURES_FRAIS.key] ? uncompleted.per_nature[NATURES_FRAIS.key] : 0
            }
        ]

        return formated_list
    }

    /**
    * On showed.
    * 
    * @param {*} selected 
    */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected._id }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { t, statistics, error, option, loading, agents, orders } = this.props

        const { start, end, selected_agent, durationVisible, userRole, detailsVisible } = this.state

        const { revenues, completed, requested, uncompleted } = statistics

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Dashboard")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Dashboard")} />

                        {[USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key, USER_BILLER.key].includes(userRole) ? (
                            <Row className="mb-2">
                                <Col xl="12">
                                    {((error != "") && [STATISTICS_SHOW].includes(option)) ? (
                                        <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                            {error}{" "}
                                            <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                                Réessayer
                                            </Link>
                                        </Alert>
                                    ) : (loading && [STATISTICS_SHOW].includes(option)) ? (
                                        <Loader color="primary" />
                                    ) : (
                                        <Row>
                                            <Col xl="6" sm="12">
                                                {(selected_agent && selected_agent._id) ? (
                                                    <small className="text-muted fw-bold">
                                                        *Les données affichées sur le tableau de bord, présentent la contribution
                                                        <br />  de l'utilisateur sélectionné, aux courses sur la période selectionnée
                                                    </small>
                                                ) : null}
                                            </Col>
                                            <Col xl="3" sm="12">
                                                {[USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key].includes(userRole) ? (
                                                    <Selecter
                                                        selected={selected_agent || null}
                                                        onSelected={this.onSelected}
                                                        options={agents}
                                                        id="selected_agent"
                                                        name="selected_agent"
                                                        placeholder="Sélectionner..."
                                                    />
                                                ) : null}
                                            </Col>
                                            <Col xl="3" sm="12">
                                                <DateRanger start={start} end={end} onRanged={this.onDateRanged} />
                                            </Col>

                                            <Col sm="12" className="my-2" />

                                            {/* GENERALS TOTALS */}
                                            {(revenues) ? (
                                                <RevenuesCard revenues={revenues} />
                                            ) : null}

                                            {(requested && completed && uncompleted) ? (
                                                <TotalsCard
                                                    totals={requested.total}
                                                    completeds={completed.total}
                                                    uncompleteds={uncompleted.total}
                                                    avg_duration={statistics.rate_time}
                                                    onClicked={this.onOrdersDurationsListed}
                                                />
                                            ) : null}

                                            <Col sm="12" className="my-2" />

                                            {/* TOTALS BY NATURES */}
                                            {(requested && completed && uncompleted) ? (
                                                <NaturesCard
                                                    totals={requested.per_nature}
                                                    completeds={completed.per_nature}
                                                    uncompleteds={uncompleted.per_nature}
                                                    natures={this.onFormated()}
                                                />
                                            ) : null}

                                            <Col sm="12" className="my-2" />
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        ) : null}
                    </Container>
                </div>
                {durationVisible ? (
                    <DurationTable
                        items={orders}
                        opened={durationVisible}
                        onClosed={this.onToggled}
                        onShowed={this.onShowed}
                        modalOption={[ORDERS_LIST].includes(option) ? option : ""}
                        modalError={[ORDERS_LIST].includes(option) ? error : ""}
                        modalLoading={[ORDERS_LIST].includes(option) ? loading : false}
                    />
                ) : null}
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Dashboard.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    statistics: PropTypes.object,
    orders: PropTypes.array,
    onResetStatistics: PropTypes.func,
    onShowStatistics: PropTypes.func,
    onListCompletedOrders: PropTypes.func,
    onListAgents: PropTypes.func
}
const mapStateToProps = state => ({
    orders: state.statistiques.completed_statistics,
    statistics: state.statistiques.all_statistics,
    error: state.statistiques.error,
    option: state.statistiques.option,
    success: state.statistiques.success,
    loading: state.statistiques.loading,
    agents: state.commandes.agents,
    orders_error: state.commandes.error,
    orders_option: state.commandes.option,
    orders_success: state.commandes.success,
    orders_loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onResetStatistics: all => dispatch(resetStatistics(all)),
    onShowStatistics: (start, end, user, option) => dispatch(showStatistics(start, end, user, option)),
    onListCompletedOrders: (start, end, option) => dispatch(completedStatistics(start, end, option)),
    onListAgents: (option) => dispatch(listAgents(USER_AGENT, false, option)),
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Dashboard)))
