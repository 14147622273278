import React from "react"
import PropTypes from 'prop-types'

// import components
import { Row, Col, Alert, Input, Label, Card, CardBody, CardTitle, CardText } from "reactstrap"

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import common custom components
import MonthPicker from "components/common/MonthPicker"


const SearchiForm = React.memo(({ query, onSearched, onChanged, onPicked }) => {

    return (
        <React.Fragment>
            {/* <Col xl="3" sm="12" className="pe-3 pe-xxl-4"> */}
            <Card className="shadow-none">
                <CardBody>
                    <Col sm="12" className="mb-5">
                        <Row>
                            <Col sm={12} className="mb-2">
                                <label htmlFor="search-period" className="form-label">
                                    Mois/Année de la recherche
                                </label>
                                <MonthPicker
                                    selected={{ month: query.month, year: query.year }}
                                    onPicked={onPicked}
                                    name="search-period"
                                />
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex align-items-end justify-content-end">
                                    <button
                                        type="submit" className="btn btn-primary px-2"
                                        onClick={() => onSearched("period")}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="bx bx-search-alt bx-xs text-white me-1" />{" "}
                                            Rechercher
                                        </div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="12" className="mb-3">
                        <Row>
                            <Col sm={12} className="mb-2">
                                <label htmlFor="code" className="form-label">
                                    Référence
                                </label>
                                <input
                                    id="code" name="code" type="text"
                                    className="form-control"
                                    onChange={(e) => onChanged(e)}
                                />
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex align-items-end justify-content-end">
                                    <button
                                        type="submit" className="btn btn-primary px-2"
                                        onClick={() => onSearched("code")}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="bx bx-search-alt bx-xs text-white me-1" />{" "}
                                            Rechercher
                                        </div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
            {/* </Col> */}
        </React.Fragment>
    );

});
SearchiForm.propTypes = {
    query: PropTypes.object,
    onPicked: PropTypes.func,
    onChanged: PropTypes.func,
    onSearched: PropTypes.func
}
SearchiForm.defaultProps = {
    query: {}
}
export default SearchiForm