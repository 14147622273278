import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorLogging, successLogging } from "./actions"
import { isUnauthorized } from "store/actions"

// import redux constants
import { LOGGING_SELECT } from "./constants"

// import constants
import { USER_KEY, USER_ROOT } from "helpers/constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import { selectLogging } from "services/loggingService"


function* onSelectLogging({ payload }) {
    try {
        const { start_date, end_date, user_id, is_root, list, option } = payload
        const response = yield call(selectLogging, start_date, end_date, user_id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const { data } = response
            let formated_list = (data ? (is_root ? data : data.filter((item) => (item.role !== USER_ROOT.key))) : [])
            let g_master = (user_id !== "") ? list.find((mx) => (mx._id === user_id)) : ""
            formated_list = formated_list.map((item) => {
                let master = (user_id != "") ? g_master : list.find((mx) => (mx._id === item.user_id))
                return { ...item,  master }
            })
            yield put(successLogging({ loggings: formated_list }, payload.option))
        } else {
            console.error(response, "SELECT LOGGING ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorLogging((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        console.error(error, "SELECT LOGGING ERROR")
        yield put(errorLogging(errorMessage(error).message, payload.option))
    }
}

function* LoggingSaga() {
    yield takeEvery(LOGGING_SELECT, onSelectLogging)
}

export default LoggingSaga