import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { resetCustomers, resetInvoices, setInvoices, showCustomer } from 'store/actions'

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Loader from 'components/common/Loader'

// import utilities
import { formatOrderNature, formatPhoneNumberForDisplay } from 'helpers/utilities'

// import constants
import { INVOICES_PAID_DETAILS, PARTNERS_SHOW } from 'helpers/constants'


class InvoiceDetails extends React.Component {

    componentDidMount() {
        try {//Load customer
            this.onLoaded()
        } catch (error) {
            console.error(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { option, onClosed } = this.props
                if (option === "") onClosed("detailsVisible", false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset states
            let { onResetCustomers, onSetInvoices, option } = this.props
            if (option === PARTNERS_SHOW) onResetCustomers(true)
            onSetInvoices({
                invoice: {}, option: "", error: "",
                loading: false, success: false,
            })
        } catch (error) {
            console.error(error)
        }
    }

    onLoaded = () => {
        try {
            let { option, invoice, onShowCustomer } = this.props
            if (option === PARTNERS_SHOW) {
                onShowCustomer(invoice.customer_id, option)
            }
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, success, onClosed, invoice, customer } = this.props
        const { bill_reference, bill_price, payment_info, bill_status, created_at, payment_at } = invoice
        const { name, phone, email, address } = customer

        return (
            <React.Fragment>
                <Modal isOpen={opened} centered={true} className={classes}>
                    <ModalHeader tag="h4">
                        Détails du {(option === PARTNERS_SHOW) ? "client" : "paiement"}
                        {(bill_reference && created_at) ? (
                            <span className="d-block fs-12 text-secondary">
                                Facture {" "}
                                <strong> #{bill_reference} </strong>
                                du {" "}
                                <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl="12">
                                {(loading && (option === PARTNERS_SHOW)) ? (
                                    <Loader color="primary" />
                                ) : (error && (error !== "") && (option === PARTNERS_SHOW)) ? (
                                    <Alert color="danger" className="mb-0">
                                        {error}
                                    </Alert>
                                ) : (invoice && invoice._id && (option === INVOICES_PAID_DETAILS)) ? (
                                    <Row>
                                        <Col xl="12" className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bx-money me-1" />
                                                        Montant
                                                    </small>
                                                    <strong> {bill_price} </strong>(XOF)
                                                </span>
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bxs-calendar me-1" />
                                                        Payée le
                                                    </small>
                                                    <strong>
                                                        {`${moment.unix(payment_at).format('DD MMM YYYY')}`}
                                                    </strong>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xl="12">
                                            <div className="p-2 bg-light rounded">
                                                <p className="m-0">
                                                    {payment_info}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (customer && customer._id && (option === PARTNERS_SHOW)) ? (
                                    <Row>
                                        <Col xl="12" className="mb-3">
                                            <h4 className="m-0">
                                                {name}
                                            </h4>
                                        </Col>
                                        <Col xl="12" className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bxs-envelope me-1" />
                                                        Email
                                                    </small>
                                                    <strong> {email} </strong>
                                                </span>
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bxs-phone me-1" />
                                                        Contact
                                                    </small>
                                                    <strong>
                                                        {formatPhoneNumberForDisplay(phone)}
                                                    </strong>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xl="12">
                                            <div className="p-2 bg-light rounded">
                                                <p className="m-0">
                                                    {address}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    <p className="p-2 mb-0">
                                        La facture n'a pas été retrouvée. Aucun élément à afficher.
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-light"
                            onClick={() => onClosed("detailsVisible", false)}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}
InvoiceDetails.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    customer: PropTypes.object,
}
InvoiceDetails.defaultProps = {
    opened: false,
    classes: "invoices-details-modal",
}
const mapStateToProps = state => ({
    invoice: state.factures.invoice,
    option: state.factures.option,
    customer: state.clients.customer,
    error: state.clients.error,
    success: state.clients.success,
    loading: state.clients.loading,
})
const mapDispatchToProps = dispatch => ({
    onResetCustomers: (all) => dispatch(resetCustomers(all)),
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onShowCustomer: (id, option) => dispatch(showCustomer(id, true, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)